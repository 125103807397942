export default {
  // OWNED & OPERATED SITES

  // OnlineSchoolsCenter.com - Best Choice Media (BCM)
  '36c5995b-5a7d-4f85-b0ad-d71112ccd8c7': {
    version: 'latest',
    type: 'publisher',
    scripts: [
      {
        src: 'ucl.scripts.cohesion.js',
        appendTo: 'head',
        properties: {
          tagular: {
            apiKey: '03ad2fa0-bade-11e9-a2a3-2a2ae2dbcce4',
            sourceKey: 'src_1PCXglf3bQJB6IxgjUojsLjmjkS',
            writeKey: 'wk_1PCXgouVOivjIgVxJUFPKlUEvQy',
            multiparty: true,
          },
        },
      },
    ],
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '36c5995b-5a7d-4f85-b0ad-d71112ccd8c7',
            publisher: 'onlineschoolscenter.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'sonic-qdf': [
        {
          path: '@',
          attributes: {
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            publisher: 'onlineschoolscenter.com',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '36c5995b-5a7d-4f85-b0ad-d71112ccd8c7',
            publisher: 'onlineschoolscenter.com',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '36c5995b-5a7d-4f85-b0ad-d71112ccd8c7',
            url: 'https://onlineschoolscenter.collegedegrees.com/app/experience/',
            publisher: 'onlineschoolscenter.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '36c5995b-5a7d-4f85-b0ad-d71112ccd8c7',
            url: 'https://onlineschoolscenter.collegedegrees.com/app/experience/',
            publisher: 'onlineschoolscenter.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '36c5995b-5a7d-4f85-b0ad-d71112ccd8c7',
            url: 'https://onlineschoolscenter.collegedegrees.com/app/experience/',
            publisher: 'onlineschoolscenter.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // AffordableSchools.net - Best Choice Media (BCM)
  '5ad5055c-0a4e-4236-a710-7e7b253b3eb7': {
    version: 'latest',
    type: 'publisher',
    scripts: [
      {
        src: 'ucl.scripts.cohesion.js',
        appendTo: 'head',
        properties: {
          tagular: {
            apiKey: '03ad2fa0-bade-11e9-a2a3-2a2ae2dbcce4',
            sourceKey: 'src_1PCXglf3bQJB6IxgjUojsLjmjkS',
            writeKey: 'wk_1PCXgouVOivjIgVxJUFPKlUEvQy',
            multiparty: true,
          },
        },
      },
    ],
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '5ad5055c-0a4e-4236-a710-7e7b253b3eb7',
            publisher: 'affordableschools.net',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'sonic-qdf': [
        {
          path: '@',
          attributes: {
            token: '5ad5055c-0a4e-4236-a710-7e7b253b3eb7',
            publisher: 'affordableschools.net',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '5ad5055c-0a4e-4236-a710-7e7b253b3eb7',
            publisher: 'affordableschools.net',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '5ad5055c-0a4e-4236-a710-7e7b253b3eb7',
            url: 'https://affordableschools.collegedegrees.com/app/experience/',
            publisher: 'affordableschools.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '5ad5055c-0a4e-4236-a710-7e7b253b3eb7',
            url: 'https://affordableschools.collegedegrees.com/app/experience/',
            publisher: 'affordableschools.net',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '5ad5055c-0a4e-4236-a710-7e7b253b3eb7',
            url: 'https://affordableschools.collegedegrees.com/app/experience/',
            publisher: 'affordableschools.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // BestCollegesOnline.org - Best Choice Media (BCM)
  '02b1635f-e8c8-461c-8c42-48c51f49c21c': {
    version: 'latest',
    type: 'publisher',
    scripts: [
      {
        src: 'ucl.scripts.cohesion.js',
        appendTo: 'head',
        properties: {
          tagular: {
            apiKey: '03ad2fa0-bade-11e9-a2a3-2a2ae2dbcce4',
            sourceKey: 'src_1PCXglf3bQJB6IxgjUojsLjmjkS',
            writeKey: 'wk_1PCXgouVOivjIgVxJUFPKlUEvQy',
            multiparty: true,
          },
        },
      },
    ],
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '02b1635f-e8c8-461c-8c42-48c51f49c21c',
            publisher: 'bestcollegesonline.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'sonic-qdf': [
        {
          path: '@',
          attributes: {
            token: '02b1635f-e8c8-461c-8c42-48c51f49c21c',
            publisher: 'bestcollegesonline.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '02b1635f-e8c8-461c-8c42-48c51f49c21c',
            publisher: 'bestcollegesonline.org',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '02b1635f-e8c8-461c-8c42-48c51f49c21c',
            url: 'https://bestcollegesonline.collegedegrees.com/app/experience/',
            publisher: 'bestcollegesonline.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '02b1635f-e8c8-461c-8c42-48c51f49c21c',
            url: 'https://bestcollegesonline.collegedegrees.com/app/experience/',
            publisher: 'bestcollegesonline.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '02b1635f-e8c8-461c-8c42-48c51f49c21c',
            url: 'https://bestcollegesonline.collegedegrees.com/app/experience/',
            publisher: 'bestcollegesonline.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // MBACentral.org - Best Choice Media (BCM)
  '67e58a76-7c9d-4cfa-a8c1-f29918b1a3ab': {
    version: 'latest',
    type: 'publisher',
    scripts: [
      {
        src: 'ucl.scripts.cohesion.js',
        appendTo: 'head',
        properties: {
          tagular: {
            apiKey: '03ad2fa0-bade-11e9-a2a3-2a2ae2dbcce4',
            sourceKey: 'src_1PCXglf3bQJB6IxgjUojsLjmjkS',
            writeKey: 'wk_1PCXgouVOivjIgVxJUFPKlUEvQy',
            multiparty: true,
          },
        },
      },
    ],
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '67e58a76-7c9d-4cfa-a8c1-f29918b1a3ab',
            publisher: 'mbacentral.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'sonic-qdf': [
        {
          path: '@',
          attributes: {
            token: '67e58a76-7c9d-4cfa-a8c1-f29918b1a3ab',
            publisher: 'mbacentral.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '67e58a76-7c9d-4cfa-a8c1-f29918b1a3ab',
            publisher: 'mbacentral.org',
          },
        },
      ],
    },
  },
  // MPHOnline.org - Best Choice Media (BCM)
  '0b449481-c4bc-41a2-8b86-fc98c593e616': {
    version: 'latest',
    type: 'publisher',
    scripts: [
      {
        src: 'ucl.scripts.cohesion.js',
        appendTo: 'head',
        properties: {
          tagular: {
            apiKey: '03ad2fa0-bade-11e9-a2a3-2a2ae2dbcce4',
            sourceKey: 'src_1PCXglf3bQJB6IxgjUojsLjmjkS',
            writeKey: 'wk_1PCXgouVOivjIgVxJUFPKlUEvQy',
            multiparty: true,
          },
        },
      },
    ],
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '0b449481-c4bc-41a2-8b86-fc98c593e616',
            publisher: 'mphonline.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'sonic-qdf': [
        {
          path: '@',
          attributes: {
            token: '0b449481-c4bc-41a2-8b86-fc98c593e616',
            publisher: 'mphonline.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '0b449481-c4bc-41a2-8b86-fc98c593e616',
            publisher: 'mphonline.org',
          },
        },
      ],
    },
  },
  // Healthcare-Management-Degree.net - Best Choice Media (BCM)
  '9c30b82b-c448-4acd-85bc-8ffbbc71fe44': {
    version: 'latest',
    type: 'publisher',
    scripts: [
      {
        src: 'ucl.scripts.cohesion.js',
        appendTo: 'head',
        properties: {
          tagular: {
            apiKey: '03ad2fa0-bade-11e9-a2a3-2a2ae2dbcce4',
            sourceKey: 'src_1PCXglf3bQJB6IxgjUojsLjmjkS',
            writeKey: 'wk_1PCXgouVOivjIgVxJUFPKlUEvQy',
            multiparty: true,
          },
        },
      },
    ],
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '9c30b82b-c448-4acd-85bc-8ffbbc71fe44',
            publisher: 'healthcare-management-degree.net',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'sonic-qdf': [
        {
          path: '@',
          attributes: {
            token: '9c30b82b-c448-4acd-85bc-8ffbbc71fe44',
            publisher: 'healthcare-management-degree.net',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9c30b82b-c448-4acd-85bc-8ffbbc71fe44',
            publisher: 'healthcare-management-degree.net',
          },
        },
      ],
    },
  },
  // GreatBusinessSchools.com - Best Choice Media (BCM)
  'b4fe81b0-6976-48a3-9b38-1d40778bfdf8': {
    version: 'latest',
    type: 'publisher',
    scripts: [
      {
        src: 'ucl.scripts.cohesion.js',
        appendTo: 'head',
        properties: {
          tagular: {
            apiKey: '03ad2fa0-bade-11e9-a2a3-2a2ae2dbcce4',
            sourceKey: 'src_1PCXglf3bQJB6IxgjUojsLjmjkS',
            writeKey: 'wk_1PCXgouVOivjIgVxJUFPKlUEvQy',
            multiparty: true,
          },
        },
      },
    ],
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'b4fe81b0-6976-48a3-9b38-1d40778bfdf8',
            publisher: 'greatbusinessschools.com',
          },
        },
      ],
      'sonic-qdf': [
        {
          path: '@',
          attributes: {
            token: 'b4fe81b0-6976-48a3-9b38-1d40778bfdf8',
            publisher: 'greatbusinessschools.com',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'b4fe81b0-6976-48a3-9b38-1d40778bfdf8',
            publisher: 'greatbusinessschools.com',
          },
        },
      ],
    },
  },
  // BestMastersPrograms.org - Best Choice Media (BCM)
  'ea70abe6-9c2e-4093-9a3e-274cd890c4f2': {
    version: 'latest',
    type: 'publisher',
    scripts: [
      {
        src: 'ucl.scripts.cohesion.js',
        appendTo: 'head',
        properties: {
          tagular: {
            apiKey: '03ad2fa0-bade-11e9-a2a3-2a2ae2dbcce4',
            sourceKey: 'src_1PCXglf3bQJB6IxgjUojsLjmjkS',
            writeKey: 'wk_1PCXgouVOivjIgVxJUFPKlUEvQy',
            multiparty: true,
          },
        },
      },
    ],
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ea70abe6-9c2e-4093-9a3e-274cd890c4f2',
            publisher: 'bestmastersprograms.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'sonic-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ea70abe6-9c2e-4093-9a3e-274cd890c4f2',
            publisher: 'bestmastersprograms.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ea70abe6-9c2e-4093-9a3e-274cd890c4f2',
            publisher: 'bestmastersprograms.org',
          },
        },
      ],
    },
  },
  // OnlineCourseReport.com - Best Choice Media (BCM)
  'f5f9596d-dd36-445c-b091-ddae39138455': {
    version: 'latest',
    type: 'publisher',
    scripts: [
      {
        src: 'ucl.scripts.cohesion.js',
        appendTo: 'head',
        properties: {
          tagular: {
            apiKey: '03ad2fa0-bade-11e9-a2a3-2a2ae2dbcce4',
            sourceKey: 'src_1PCXglf3bQJB6IxgjUojsLjmjkS',
            writeKey: 'wk_1PCXgouVOivjIgVxJUFPKlUEvQy',
            multiparty: true,
          },
        },
      },
    ],
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'f5f9596d-dd36-445c-b091-ddae39138455',
            publisher: 'onlinecoursereport.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'sonic-qdf': [
        {
          path: '@',
          attributes: {
            token: 'f5f9596d-dd36-445c-b091-ddae39138455',
            publisher: 'onlinecoursereport.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'f5f9596d-dd36-445c-b091-ddae39138455',
            publisher: 'onlinecoursereport.com',
          },
        },
      ],
    },
  },

  // RV PARTNERS
  // Bankrate.com
  '3ed5b9f4-9f8e-4cf7-bcc4-2c0b6ff9f357': {
    version: 'latest',
    type: 'publisher',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '3ed5b9f4-9f8e-4cf7-bcc4-2c0b6ff9f357',
            url: 'https://bankrate.collegedegrees.com/app/experience/',
            publisher: 'bankrate.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '3ed5b9f4-9f8e-4cf7-bcc4-2c0b6ff9f357',
            url: 'https://bankrate.collegedegrees.com/app/experience/',
            publisher: 'bankrate.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // cnet.com
  '8ad94ee5-7ca2-47b0-bad7-56c84ebad89f': {
    version: 'latest',
    type: 'publisher',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '8ad94ee5-7ca2-47b0-bad7-56c84ebad89f',
            url: 'https://cnet.collegedegrees.com/app/experience/',
            publisher: 'cnet.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '8ad94ee5-7ca2-47b0-bad7-56c84ebad89f',
            url: 'https://cnet.collegedegrees.com/app/experience/',
            publisher: 'cnet.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // Healthline.com
  'dac89700-7c84-4d20-a191-b7a06d67b37a': {
    version: 'latest',
    type: 'publisher',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'dac89700-7c84-4d20-a191-b7a06d67b37a',
            url: 'https://healthline.collegedegrees.com/app/experience/',
            publisher: 'healthline.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'dac89700-7c84-4d20-a191-b7a06d67b37a',
            url: 'https://healthline.collegedegrees.com/app/experience/',
            publisher: 'healthline.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // ZDNet.com
  'e24338e3-c933-4432-8982-67239d967c31': {
    version: 'latest',
    type: 'publisher',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'e24338e3-c933-4432-8982-67239d967c31',
            url: 'https://zdnet.collegedegrees.com/app/experience/',
            publisher: 'zdnet.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'e24338e3-c933-4432-8982-67239d967c31',
            url: 'https://zdnet.collegedegrees.com/app/experience/',
            publisher: 'zdnet.com',
            theme: 'none',
          },
        },
      ],
    },
  },

  // RV EDU AFFILIATES
  // BCM: MastersProgramsGuide.com
  'e760f42f-0ecc-46d6-b899-cba35d6a7027': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'e760f42f-0ecc-46d6-b899-cba35d6a7027',
            url: 'https://mastersprogramsguide.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            publisher: 'mastersprogramsguide.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'e760f42f-0ecc-46d6-b899-cba35d6a7027',
            url: 'https://mastersprogramsguide.collegedegrees.com/app/experience/',
            publisher: 'mastersprogramsguide.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // BCM: OnlineCollegePlan.com
  '2649673f-9160-4a11-954b-52ac9f382415': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '2649673f-9160-4a11-954b-52ac9f382415',
            url: 'https://onlinecollegeplan.collegedegrees.com/app/experience/',
            publisher: 'onlinecollegeplan.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '2649673f-9160-4a11-954b-52ac9f382415',
            url: 'https://onlinecollegeplan.collegedegrees.com/app/experience/',
            publisher: 'onlinecollegeplan.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // BestValueSchools.org
  '7896fb10-7d8e-4829-8dda-17a5dd1a0a99': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '7896fb10-7d8e-4829-8dda-17a5dd1a0a99',
            url: 'https://bestvalueschools.collegedegrees.com/app/experience/',
            publisher: 'bestvalueschools.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '7896fb10-7d8e-4829-8dda-17a5dd1a0a99',
            url: 'https://bestvalueschools.collegedegrees.com/app/experience/',
            publisher: 'bestvalueschools.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // Career Marketing Online: RNCareers.org
  'b2120c1e-d209-4072-a1e7-1025fdec29e1': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'b2120c1e-d209-4072-a1e7-1025fdec29e1',
            url: 'https://rncareers.collegedegrees.com/app/experience/',
            publisher: 'rncareers.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'b2120c1e-d209-4072-a1e7-1025fdec29e1',
            url: 'https://rncareers.collegedegrees.com/app/experience/',
            publisher: 'rncareers.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // Career Marketing Online: MBAGuide.org
  'dcb86f2d-c852-4ca3-bc67-583653c474cb': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'dcb86f2d-c852-4ca3-bc67-583653c474cb',
            url: 'https://mbaguide.collegedegrees.com/app/experience/',
            publisher: 'mbaguide.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'dcb86f2d-c852-4ca3-bc67-583653c474cb',
            url: 'https://mbaguide.collegedegrees.com/app/experience/',
            publisher: 'mbaguide.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // CollegeConsensus.com
  '32dc9e29-7e3c-49ca-a11a-9a20cd9e35b9': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '32dc9e29-7e3c-49ca-a11a-9a20cd9e35b9',
            url: 'https://collegeconsensus.collegedegrees.com/app/experience/',
            publisher: 'collegeconsensus.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '32dc9e29-7e3c-49ca-a11a-9a20cd9e35b9',
            url: 'https://collegeconsensus.collegedegrees.com/app/experience/',
            publisher: 'collegeconsensus.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '32dc9e29-7e3c-49ca-a11a-9a20cd9e35b9',
            url: 'https://collegeconsensus.collegedegrees.com/app/experience/',
            publisher: 'collegeconsensus.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '32dc9e29-7e3c-49ca-a11a-9a20cd9e35b9',
            url: 'https://collegeconsensus.collegedegrees.com/app/experience/',
            publisher: 'collegeconsensus.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '32dc9e29-7e3c-49ca-a11a-9a20cd9e35b9',
            url: 'https://collegeconsensus.collegedegrees.com/app/experience/',
            publisher: 'collegeconsensus.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // DegreePlanet.com
  'ff0e3008-f2f9-42ec-8c8e-b9a245432d6b': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ff0e3008-f2f9-42ec-8c8e-b9a245432d6b',
            url: 'https://degreeplanet.collegedegrees.com/app/experience/',
            publisher: 'degreeplanet.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ff0e3008-f2f9-42ec-8c8e-b9a245432d6b',
            url: 'https://degreeplanet.collegedegrees.com/app/experience/',
            publisher: 'degreeplanet.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // EdSmart.org
  '4eccdd2e-6587-4641-ba75-f8f6e6c596f6': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '4eccdd2e-6587-4641-ba75-f8f6e6c596f6',
            url: 'https://edsmart.collegedegrees.com/app/experience/',
            publisher: 'edsmart.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '4eccdd2e-6587-4641-ba75-f8f6e6c596f6',
            url: 'https://edsmart.collegedegrees.com/app/experience/',
            publisher: 'edsmart.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '4eccdd2e-6587-4641-ba75-f8f6e6c596f6',
            url: 'https://edsmart.collegedegrees.com/app/experience/',
            publisher: 'edsmart.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '4eccdd2e-6587-4641-ba75-f8f6e6c596f6',
            url: 'https://edsmart.collegedegrees.com/app/experience/',
            publisher: 'edsmart.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '4eccdd2e-6587-4641-ba75-f8f6e6c596f6',
            url: 'https://edsmart.collegedegrees.com/app/experience/',
            publisher: 'edsmart.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // Invigo: NursingProcess.org
  '5b06c053-b091-431d-bf07-fba218b28892': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '5b06c053-b091-431d-bf07-fba218b28892',
            url: 'https://nursingprocess.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            publisher: 'nursingprocess.org',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '5b06c053-b091-431d-bf07-fba218b28892',
            url: 'https://nursingprocess.collegedegrees.com/app/experience/',
            publisher: 'nursingprocess.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // MyDegreeGuide.com
  '578a0098-fc59-4ebe-a7e4-82f013d2da46': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '578a0098-fc59-4ebe-a7e4-82f013d2da46',
            url: 'https://mydegreeguide.collegedegrees.com/app/experience/',
            publisher: 'mydegreeguide.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '578a0098-fc59-4ebe-a7e4-82f013d2da46',
            url: 'https://mydegreeguide.collegedegrees.com/app/experience/',
            publisher: 'mydegreeguide.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '578a0098-fc59-4ebe-a7e4-82f013d2da46',
            url: 'https://mydegreeguide.collegedegrees.com/app/experience/',
            publisher: 'mydegreeguide.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '578a0098-fc59-4ebe-a7e4-82f013d2da46',
            url: 'https://mydegreeguide.collegedegrees.com/app/experience/',
            publisher: 'mydegreeguide.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '578a0098-fc59-4ebe-a7e4-82f013d2da46',
            url: 'https://mydegreeguide.collegedegrees.com/app/experience/',
            publisher: 'mydegreeguide.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // USNews.com
  '0c9cb887-74d1-41e8-8c6d-3427b022d439': {
    version: 'latest',
    type: 'affiliate',
    scripts: [
      {
        src: 'ucl.scripts.cohesion.js',
        appendTo: 'head',
        properties: {
          tagular: {
            apiKey: 'src_1Ul6AE2sh936m8HBuWwxeswPFuS',
            sourceKey: 'src_1Ul6AE2sh936m8HBuWwxeswPFuS',
            writeKey: 'wk_1Ul6A7krhjx5ktCHa0lu9XiILD0',
          },
        },
      },
    ],
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '0c9cb887-74d1-41e8-8c6d-3427b022d439',
            url: 'https://edu-request.usnews.com/app/experience/',
            publisher: 'usnews.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-qdf-usnews': [
        {
          path: '@',
          attributes: {
            token: '0c9cb887-74d1-41e8-8c6d-3427b022d439',
            url: 'https://edu-request.usnews.com/app/experience/',
            publisher: 'usnews.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '0c9cb887-74d1-41e8-8c6d-3427b022d439',
            url: 'https://edu-request.usnews.com/app/experience/',
            publisher: 'usnews.com',
            theme: 'none',
          },
        },
      ],
      'he-usnews-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '0c9cb887-74d1-41e8-8c6d-3427b022d439',
            url: 'https://edu-request.usnews.com/app/experience/',
            publisher: 'usnews.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // GradSchoolCenter.com
  '5e3454b2-eb78-4ffe-8e58-ec822d91e402': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '5e3454b2-eb78-4ffe-8e58-ec822d91e402',
            url: 'https://gradschoolcenter.collegedegrees.com/app/experience/',
            publisher: 'gradschoolcenter.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '5e3454b2-eb78-4ffe-8e58-ec822d91e402',
            url: 'https://gradschoolcenter.collegedegrees.com/app/experience/',
            publisher: 'gradschoolcenter.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // CollegeCliffs.com
  'ea175017-48a6-46bb-99b1-482de8ffc58e': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ea175017-48a6-46bb-99b1-482de8ffc58e',
            url: 'https://collegecliffs.collegedegrees.com/app/experience/',
            publisher: 'collegecliffs.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ea175017-48a6-46bb-99b1-482de8ffc58e',
            url: 'https://collegecliffs.collegedegrees.com/app/experience/',
            publisher: 'collegecliffs.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // Eduref.net
  'e12e1611-cacb-4b3b-8910-6dca71d7efd3': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'e12e1611-cacb-4b3b-8910-6dca71d7efd3',
            publisher: 'eduref.net',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'e12e1611-cacb-4b3b-8910-6dca71d7efd3',
            publisher: 'eduref.net',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: 'e12e1611-cacb-4b3b-8910-6dca71d7efd3',
            url: 'https://eduref.collegedegrees.com/app/experience/',
            publisher: 'eduref.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'e12e1611-cacb-4b3b-8910-6dca71d7efd3',
            url: 'https://eduref.collegedegrees.com/app/experience/',
            publisher: 'eduref.net',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: 'e12e1611-cacb-4b3b-8910-6dca71d7efd3',
            url: 'https://eduref.collegedegrees.com/app/experience/',
            publisher: 'eduref.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // TheArtCareerProject.com
  'c44dfb0f-9a66-412c-a91e-2d242bd953d1': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'c44dfb0f-9a66-412c-a91e-2d242bd953d1',
            publisher: 'theartcareerproject.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'c44dfb0f-9a66-412c-a91e-2d242bd953d1',
            publisher: 'theartcareerproject.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // Accredited-Online-College.org
  '31ac2ec3-54cb-41bb-a695-6a71d833f9ad': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '31ac2ec3-54cb-41bb-a695-6a71d833f9ad',
            url: 'https://accredited-online-college.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            publisher: 'accredited-online-college.org',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '31ac2ec3-54cb-41bb-a695-6a71d833f9ad',
            url: 'https://accredited-online-college.collegedegrees.com/app/experience/',
            publisher: 'accredited-online-college.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // Nonprofitcollegesonline.com
  'd4789abb-8840-4d90-aaba-6cda1d194af0': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'd4789abb-8840-4d90-aaba-6cda1d194af0',
            url: 'https://nonprofitcollegesonline.collegedegrees.com/app/experience/',
            publisher: 'nonprofitcollegesonline.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd4789abb-8840-4d90-aaba-6cda1d194af0',
            url: 'https://nonprofitcollegesonline.collegedegrees.com/app/experience/',
            publisher: 'nonprofitcollegesonline.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // Premiumschools.org
  'b38e87e3-8cc4-4282-8346-9e467a2987fc': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'b38e87e3-8cc4-4282-8346-9e467a2987fc',
            url: 'https://premiumschools.collegedegrees.com/app/experience/',
            publisher: 'premiumschools.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'b38e87e3-8cc4-4282-8346-9e467a2987fc',
            url: 'https://premiumschools.collegedegrees.com/app/experience/',
            publisher: 'premiumschools.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // Degreequery.com
  'b350bc04-9228-4a6b-ac3a-30ffa9dcbe53': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'b350bc04-9228-4a6b-ac3a-30ffa9dcbe53',
            url: 'https://degreequery.collegedegrees.com/app/experience/',
            publisher: 'degreequery.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'b350bc04-9228-4a6b-ac3a-30ffa9dcbe53',
            url: 'https://degreequery.collegedegrees.com/app/experience/',
            publisher: 'degreequery.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // Collegevaluesonline.com
  '597269f2-0563-4dd0-9af7-a98b41254916': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '597269f2-0563-4dd0-9af7-a98b41254916',
            url: 'https://collegevaluesonline.collegedegrees.com/app/experience/',
            publisher: 'collegevaluesonline.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '597269f2-0563-4dd0-9af7-a98b41254916',
            url: 'https://collegevaluesonline.collegedegrees.com/app/experience/',
            publisher: 'collegevaluesonline.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // collegerank.net
  'ea700733-64b0-4e1c-8821-d91cb749091a': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ea700733-64b0-4e1c-8821-d91cb749091a',
            url: 'https://collegerank.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            publisher: 'collegerank.net',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ea700733-64b0-4e1c-8821-d91cb749091a',
            url: 'https://collegerank.collegedegrees.com/app/experience/',
            publisher: 'collegerank.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // appliedbehavioranalysisprograms.com
  '79fadc09-de44-4c32-8c0e-24c7750cdae7': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '79fadc09-de44-4c32-8c0e-24c7750cdae7',
            url: 'https://appliedbehavioranalysisprograms.collegedegrees.com/app/experience/',
            publisher: 'appliedbehavioranalysisprograms.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '79fadc09-de44-4c32-8c0e-24c7750cdae7',
            url: 'https://appliedbehavioranalysisprograms.collegedegrees.com/app/experience/',
            publisher: 'appliedbehavioranalysisprograms.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // valuecolleges.com
  '524b95e7-f6a2-40f7-9e0b-2788235e3a3c': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '524b95e7-f6a2-40f7-9e0b-2788235e3a3c',
            url: 'https://valuecolleges.collegedegrees.com/app/experience/',
            publisher: 'valuecolleges.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '524b95e7-f6a2-40f7-9e0b-2788235e3a3c',
            url: 'https://valuecolleges.collegedegrees.com/app/experience/',
            publisher: 'valuecolleges.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // greatcollegedeals.net
  '599ef09e-a63e-46d5-8a86-b9dda8041a7f': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '599ef09e-a63e-46d5-8a86-b9dda8041a7f',
            url: 'https://greatcollegedeals.collegedegrees.com/app/experience/',
            publisher: 'greatcollegedeals.net',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '599ef09e-a63e-46d5-8a86-b9dda8041a7f',
            url: 'https://greatcollegedeals.collegedegrees.com/app/experience/',
            publisher: 'greatcollegedeals.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // pharmacytechnicianguide.com
  '60c54a23-505f-4b36-ab68-e06a3e489628': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '60c54a23-505f-4b36-ab68-e06a3e489628',
            publisher: 'pharmacytechnicianguide.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '60c54a23-505f-4b36-ab68-e06a3e489628',
            publisher: 'pharmacytechnicianguide.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // RNtoBSNProgram.com
  '1d92f9d0-885f-4bc5-8337-c2903267b451': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '1d92f9d0-885f-4bc5-8337-c2903267b451',
            publisher: 'RNtoBSNProgram.com',
            url: 'https://rntobsnprogram.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '1d92f9d0-885f-4bc5-8337-c2903267b451',
            publisher: 'RNtoBSNProgram.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '1d92f9d0-885f-4bc5-8337-c2903267b451',
            url: 'https://RNtoBSNProgram.collegedegrees.com/app/experience/',
            publisher: 'RNtoBSNProgram.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '1d92f9d0-885f-4bc5-8337-c2903267b451',
            url: 'https://rntobsnprogram.collegedegrees.com/app/experience/',
            publisher: 'RNtoBSNProgram.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '1d92f9d0-885f-4bc5-8337-c2903267b451',
            url: 'https://rntobsnprogram.collegedegrees.com/app/experience/',
            publisher: 'RNtoBSNProgram.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  //Rn to Bsn online program
  'b93b24bc-8bfb-44db-b79c-bf374eb74bed': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'b93b24bc-8bfb-44db-b79c-bf374eb74bed',
            publisher: 'rntobsnonlineprogram.com',
            url: 'https://rntobsnonlineprogram.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'b93b24bc-8bfb-44db-b79c-bf374eb74bed',
            publisher: 'rntobsnonlineprogram.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // fastonlinemasters.com
  '70f0eb74-a993-4a66-9964-ab1d33f94424': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '70f0eb74-a993-4a66-9964-ab1d33f94424',
            publisher: 'fastonlinemasters.com',
            url: 'https://fastonlinemasters.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '70f0eb74-a993-4a66-9964-ab1d33f94424',
            publisher: 'fastonlinemasters.com',
            url: 'https://fastonlinemasters.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // best-universites.net
  'deeffeae-c91d-4155-b8b5-24a1a0fb9381': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'deeffeae-c91d-4155-b8b5-24a1a0fb9381',
            publisher: 'best-universities.net',
            url: 'https://best-universities.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'deeffeae-c91d-4155-b8b5-24a1a0fb9381',
            publisher: 'best-universities.net',
            url: 'https://best-universities.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: 'deeffeae-c91d-4155-b8b5-24a1a0fb9381',
            url: 'https://best-universities.collegedegrees.com/app/experience/',
            publisher: 'best-universities.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'deeffeae-c91d-4155-b8b5-24a1a0fb9381',
            url: 'https://best-universities.collegedegrees.com/app/experience/',
            publisher: 'best-universities.net',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: 'deeffeae-c91d-4155-b8b5-24a1a0fb9381',
            url: 'https://best-universities.collegedegrees.com/app/experience/',
            publisher: 'best-universities.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // collegeranker.com
  '44b1674d-3670-4da3-a91b-f87b251cd018': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '44b1674d-3670-4da3-a91b-f87b251cd018',
            publisher: 'collegeranker.com',
            url: 'https://collegeranker.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '44b1674d-3670-4da3-a91b-f87b251cd018',
            publisher: 'collegeranker.com',
            url: 'https://collegeranker.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // teachercertificationdegrees.com
  '9fb6990d-1e20-4cb6-ab89-be268cd885c8': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '9fb6990d-1e20-4cb6-ab89-be268cd885c8',
            publisher: 'teachercertificationdegrees.com',
            url: 'https://teachercertificationdegrees.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9fb6990d-1e20-4cb6-ab89-be268cd885c8',
            publisher: 'teachercertificationdegrees.com',
            url: 'https://teachercertificationdegrees.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // humanresourcesmba.net
  '2e162299-22bd-445e-bf8a-fbb000570ea2': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '2e162299-22bd-445e-bf8a-fbb000570ea2',
            publisher: 'humanresourcesmba.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '2e162299-22bd-445e-bf8a-fbb000570ea2',
            publisher: 'humanresourcesmba.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestdegreeprograms.org
  '6cb5d933-f7e5-44bd-afec-3f223f257e55': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '6cb5d933-f7e5-44bd-afec-3f223f257e55',
            publisher: 'bestdegreeprograms.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '6cb5d933-f7e5-44bd-afec-3f223f257e55',
            publisher: 'bestdegreeprograms.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // socialworkdegreecenter.com
  'c9e0353f-ac99-4bf3-a52c-7a7f6c11acd5': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'c9e0353f-ac99-4bf3-a52c-7a7f6c11acd5',
            publisher: 'socialworkdegreecenter.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'c9e0353f-ac99-4bf3-a52c-7a7f6c11acd5',
            publisher: 'socialworkdegreecenter.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // online-psychology-degrees.org
  'd2340d0b-ca9b-4cdd-ad2f-2de06c4b93db': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'd2340d0b-ca9b-4cdd-ad2f-2de06c4b93db',
            publisher: 'online-psychology-degrees.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd2340d0b-ca9b-4cdd-ad2f-2de06c4b93db',
            publisher: 'online-psychology-degrees.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // top10onlinecolleges.org
  '5c89555e-953a-4e9f-b5ea-61c6cccadaa2': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '5c89555e-953a-4e9f-b5ea-61c6cccadaa2',
            publisher: 'top10onlinecolleges.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '5c89555e-953a-4e9f-b5ea-61c6cccadaa2',
            publisher: 'top10onlinecolleges.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bachelorsdegreecenter.org
  'e88e9eb0-5fc6-402c-ba11-5973dbeb3f42': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'e88e9eb0-5fc6-402c-ba11-5973dbeb3f42',
            publisher: 'bachelorsdegreecenter.org',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'e88e9eb0-5fc6-402c-ba11-5973dbeb3f42',
            publisher: 'bachelorsdegreecenter.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // online-bachelor-degrees.com
  '39728745-f804-4424-929d-a758c71eed8c': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '39728745-f804-4424-929d-a758c71eed8c',
            publisher: 'online-bachelor-degrees.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '39728745-f804-4424-929d-a758c71eed8c',
            publisher: 'online-bachelor-degrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // topcounselingschools.org
  'ee60531a-d45f-42c8-962c-c8ddc3f67939': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ee60531a-d45f-42c8-962c-c8ddc3f67939',
            publisher: 'topcounselingschools.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ee60531a-d45f-42c8-962c-c8ddc3f67939',
            publisher: 'topcounselingschools.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // sports-management-degrees.com
  'f20ba302-58e8-4d37-8f7c-9296f5671344': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'f20ba302-58e8-4d37-8f7c-9296f5671344',
            publisher: 'sports-management-degrees.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'f20ba302-58e8-4d37-8f7c-9296f5671344',
            publisher: 'sports-management-degrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // online-phd-degrees.com
  '042ec984-e30e-412b-a4e6-6a82294a8eef': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '042ec984-e30e-412b-a4e6-6a82294a8eef',
            publisher: 'online-phd-degrees.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '042ec984-e30e-412b-a4e6-6a82294a8eef',
            publisher: 'online-phd-degrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // early-childhood-education-degrees.com
  'ebaa02b8-4a95-46cb-9fa1-226abdab4d1c': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ebaa02b8-4a95-46cb-9fa1-226abdab4d1c',
            publisher: 'early-childhood-education-degrees.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ebaa02b8-4a95-46cb-9fa1-226abdab4d1c',
            publisher: 'early-childhood-education-degrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },

  // criminaljusticedegreehub.com
  '969a0ab9-71cc-418d-8775-e07bfc594c4b': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '969a0ab9-71cc-418d-8775-e07bfc594c4b',
            publisher: 'criminaljusticedegreehub.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '969a0ab9-71cc-418d-8775-e07bfc594c4b',
            publisher: 'criminaljusticedegreehub.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // organizationalpsychologydegrees.com
  '057c5914-acf3-4557-a372-25811619fdf7': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '057c5914-acf3-4557-a372-25811619fdf7',
            publisher: 'organizationalpsychologydegrees.com',
            url: 'https://organizationalpsychologydegrees.collegedegrees.com/app/experience',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '057c5914-acf3-4557-a372-25811619fdf7',
            publisher: 'organizationalpsychologydegrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // onlinepsychologydegree.info
  '07a60af0-1fc0-47de-8f67-8d21de24af53': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '07a60af0-1fc0-47de-8f67-8d21de24af53',
            publisher: 'onlinepsychologydegree.info',
            url: 'https://onlinepsychologydegree.collegedegrees.com/app/experience',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '07a60af0-1fc0-47de-8f67-8d21de24af53',
            publisher: 'onlinepsychologydegree.info',
            theme: 'none',
          },
        },
      ],
    },
  },
  // besthealthdegrees.com
  '6aba6e33-e6cf-4377-93e2-1421aa1f163b': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '6aba6e33-e6cf-4377-93e2-1421aa1f163b',
            publisher: 'besthealthdegrees.com',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '6aba6e33-e6cf-4377-93e2-1421aa1f163b',
            publisher: 'besthealthdegrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // nursingschoolhub.com
  'f4d79cba-3120-41f6-8d28-ffaf86a1a219': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'f4d79cba-3120-41f6-8d28-ffaf86a1a219',
            publisher: 'nursingschoolhub.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'f4d79cba-3120-41f6-8d28-ffaf86a1a219',
            publisher: 'nursingschoolhub.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // securitydegreehub.com
  'a3286045-f7df-4b68-a2a3-d7b3b7c30fb4': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'a3286045-f7df-4b68-a2a3-d7b3b7c30fb4',
            publisher: 'securitydegreehub.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'a3286045-f7df-4b68-a2a3-d7b3b7c30fb4',
            publisher: 'securitydegreehub.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // computersciencedegreehub.com
  '765c6495-93d2-4b55-becc-321933ed6d58': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '765c6495-93d2-4b55-becc-321933ed6d58',
            publisher: 'computersciencedegreehub.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '765c6495-93d2-4b55-becc-321933ed6d58',
            publisher: 'computersciencedegreehub.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // business-management-degree.net
  '544782d6-915b-44e4-b35a-92863b947252': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '544782d6-915b-44e4-b35a-92863b947252',
            publisher: 'business-management-degree.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '544782d6-915b-44e4-b35a-92863b947252',
            publisher: 'business-management-degree.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // graphicdesigndegreehub.com
  '1e9c49b5-14b4-48a5-9ae2-055c103540f5': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '1e9c49b5-14b4-48a5-9ae2-055c103540f5',
            publisher: 'graphicdesigndegreehub.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '1e9c49b5-14b4-48a5-9ae2-055c103540f5',
            publisher: 'graphicdesigndegreehub.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestchoiceschools.com
  'a9ff76bd-53b6-43e5-8739-8c8f63ee4dfa': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'a9ff76bd-53b6-43e5-8739-8c8f63ee4dfa',
            publisher: 'bestchoiceschools.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'a9ff76bd-53b6-43e5-8739-8c8f63ee4dfa',
            publisher: 'bestchoiceschools.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // online-paralegal-programs.com
  '11a86a4f-de97-41a0-b31c-45eac82316f5': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '11a86a4f-de97-41a0-b31c-45eac82316f5',
            publisher: 'online-paralegal-programs.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '11a86a4f-de97-41a0-b31c-45eac82316f5',
            publisher: 'online-paralegal-programs.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // online-paralegal-degree.org
  '32c74c78-e604-498a-9a4d-5c26de294261': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '32c74c78-e604-498a-9a4d-5c26de294261',
            publisher: 'online-paralegal-degree.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '32c74c78-e604-498a-9a4d-5c26de294261',
            publisher: 'online-paralegal-degree.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // datasciencedegreeprograms.net
  'c1b7517a-523a-4a6a-9823-aa9fce5d07f5': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'c1b7517a-523a-4a6a-9823-aa9fce5d07f5',
            publisher: 'datasciencedegreeprograms.net',
            url: 'https://datasciencedegreeprograms.collegedegrees.com/app/experience',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'c1b7517a-523a-4a6a-9823-aa9fce5d07f5',
            publisher: 'datasciencedegreeprograms.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // master-of-finance.org
  'ed2cb17b-afe1-45af-a830-69a7872566c3': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ed2cb17b-afe1-45af-a830-69a7872566c3',
            publisher: 'master-of-finance.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ed2cb17b-afe1-45af-a830-69a7872566c3',
            publisher: 'master-of-finance.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestmarketingdegrees.org
  '4518f6fa-039f-42d2-bab5-d3f721b42c9e': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '4518f6fa-039f-42d2-bab5-d3f721b42c9e',
            publisher: 'bestmarketingdegrees.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '4518f6fa-039f-42d2-bab5-d3f721b42c9e',
            publisher: 'bestmarketingdegrees.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // onlinechristiancolleges.com
  '47787e96-a289-4b0e-aae4-c09fb81956a1': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '47787e96-a289-4b0e-aae4-c09fb81956a1',
            publisher: 'onlinechristiancolleges.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '47787e96-a289-4b0e-aae4-c09fb81956a1',
            publisher: 'onlinechristiancolleges.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // topeducationdegrees.org
  '136c9c4c-d338-44b3-b167-751305f9bea3': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '136c9c4c-d338-44b3-b167-751305f9bea3',
            publisher: 'topeducationdegrees.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '136c9c4c-d338-44b3-b167-751305f9bea3',
            publisher: 'topeducationdegrees.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // topaccountingdegrees.org
  'a7450874-3474-4ecd-8493-648a1b0a5992': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'a7450874-3474-4ecd-8493-648a1b0a5992',
            publisher: 'topaccountingdegrees.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'a7450874-3474-4ecd-8493-648a1b0a5992',
            publisher: 'topaccountingdegrees.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestmswprograms.com
  'cf59bbf9-3dd5-4013-97bb-84c6248d2870': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'cf59bbf9-3dd5-4013-97bb-84c6248d2870',
            publisher: 'bestmswprograms.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'cf59bbf9-3dd5-4013-97bb-84c6248d2870',
            publisher: 'bestmswprograms.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // masterspublichealth.net
  'a5caca6b-15db-4244-ac90-0ad4e88c27cf': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'a5caca6b-15db-4244-ac90-0ad4e88c27cf',
            publisher: 'masterspublichealth.net',
            url: 'https://masterspublichealth.collegedegrees.com/app/experience',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'a5caca6b-15db-4244-ac90-0ad4e88c27cf',
            publisher: 'masterspublichealth.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // cybersecuritydegrees.com
  'bcb19798-bfe5-4f8a-a212-d23eb7bd1ff6': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'bcb19798-bfe5-4f8a-a212-d23eb7bd1ff6',
            publisher: 'cybersecuritydegrees.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'bcb19798-bfe5-4f8a-a212-d23eb7bd1ff6',
            publisher: 'cybersecuritydegrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // onlinemastersinpublichealth.com
  '0849372d-fb06-4419-9ede-a0d85a69e184': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '0849372d-fb06-4419-9ede-a0d85a69e184',
            publisher: 'onlinemastersinpublichealth.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '0849372d-fb06-4419-9ede-a0d85a69e184',
            publisher: 'onlinemastersinpublichealth.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // sportsmanagementdegreehub.com
  '51547fa3-8a36-4342-9620-b7db71360e7f': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '51547fa3-8a36-4342-9620-b7db71360e7f',
            publisher: 'sportsmanagementdegreehub.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '51547fa3-8a36-4342-9620-b7db71360e7f',
            publisher: 'sportsmanagementdegreehub.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // besthospitalitydegrees.com
  '78db1523-ff1c-4bc1-a38b-0d44982ed11f': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '78db1523-ff1c-4bc1-a38b-0d44982ed11f',
            publisher: 'besthospitalitydegrees.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '78db1523-ff1c-4bc1-a38b-0d44982ed11f',
            publisher: 'besthospitalitydegrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // computersciencezone.org
  'df97c49b-3841-4cb6-9304-fffd6efc2af8': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'df97c49b-3841-4cb6-9304-fffd6efc2af8',
            publisher: 'computersciencezone.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'df97c49b-3841-4cb6-9304-fffd6efc2af8',
            publisher: 'computersciencezone.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // superscholar.org
  '763bebbd-3807-454e-8038-104746d13143': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '763bebbd-3807-454e-8038-104746d13143',
            publisher: 'superscholar.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '763bebbd-3807-454e-8038-104746d13143',
            publisher: 'superscholar.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // topmedicalcodingschools.com
  'bc2e607b-addc-443d-9a50-5c1edd673eae': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'bc2e607b-addc-443d-9a50-5c1edd673eae',
            publisher: 'topmedicalcodingschools.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'bc2e607b-addc-443d-9a50-5c1edd673eae',
            publisher: 'topmedicalcodingschools.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // onlineschoolsreport.com
  'b7401e0d-cf1c-48c4-8390-3d908339d9c0': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'b7401e0d-cf1c-48c4-8390-3d908339d9c0',
            publisher: 'onlineschoolsreport.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'b7401e0d-cf1c-48c4-8390-3d908339d9c0',
            publisher: 'onlineschoolsreport.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // toprntobsn.com
  '874848cf-d632-4548-8b76-288ba464bc54': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '874848cf-d632-4548-8b76-288ba464bc54',
            publisher: 'toprntobsn.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '874848cf-d632-4548-8b76-288ba464bc54',
            publisher: 'toprntobsn.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // internationalbusinessguide.org
  '4534e988-c013-403c-b11d-e153311c518f': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '4534e988-c013-403c-b11d-e153311c518f',
            publisher: 'internationalbusinessguide.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '4534e988-c013-403c-b11d-e153311c518f',
            publisher: 'internationalbusinessguide.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // webdesigndegreecenter.org
  '0c4acd45-dd7c-4eb9-8458-5695e0555ded': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '0c4acd45-dd7c-4eb9-8458-5695e0555ded',
            publisher: 'webdesigndegreecenter.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '0c4acd45-dd7c-4eb9-8458-5695e0555ded',
            publisher: 'webdesigndegreecenter.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // besteducationdegrees.com
  'da38005a-7318-43e6-b9ee-7e449a75897c': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'da38005a-7318-43e6-b9ee-7e449a75897c',
            publisher: 'besteducationdegrees.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'da38005a-7318-43e6-b9ee-7e449a75897c',
            publisher: 'besteducationdegrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestcollegevalues.com
  'f25dcf91-36c1-4a30-acdf-64fd8ee49b7d': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'f25dcf91-36c1-4a30-acdf-64fd8ee49b7d',
            publisher: 'bestcollegevalues.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'f25dcf91-36c1-4a30-acdf-64fd8ee49b7d',
            publisher: 'bestcollegevalues.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // mba-healthcare-management.com
  '3d4c3c58-75d6-4981-a2be-15d7e9644d74': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '3d4c3c58-75d6-4981-a2be-15d7e9644d74',
            publisher: 'mba-healthcare-management.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '3d4c3c58-75d6-4981-a2be-15d7e9644d74',
            publisher: 'mba-healthcare-management.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestcomputersciencedegrees.com
  '6a040c19-fe71-4d3e-84df-0cd556368891': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '6a040c19-fe71-4d3e-84df-0cd556368891',
            publisher: 'bestcomputersciencedegrees.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '6a040c19-fe71-4d3e-84df-0cd556368891',
            publisher: 'bestcomputersciencedegrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // businessresearchguide.com
  '28c34834-eebd-4d10-9fad-0ac6453139a2': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '28c34834-eebd-4d10-9fad-0ac6453139a2',
            publisher: 'businessresearchguide.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '28c34834-eebd-4d10-9fad-0ac6453139a2',
            publisher: 'businessresearchguide.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestcomputerscienceschools.net
  '405a7570-8a20-4524-bfb2-f06ac067f0c9': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '405a7570-8a20-4524-bfb2-f06ac067f0c9',
            publisher: 'bestcomputerscienceschools.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '405a7570-8a20-4524-bfb2-f06ac067f0c9',
            publisher: 'bestcomputerscienceschools.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // educational-leadership-degrees.com
  'f8c90204-1fb7-4987-a3ff-6d4c6298a516': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'f8c90204-1fb7-4987-a3ff-6d4c6298a516',
            publisher: 'educational-leadership-degrees.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'f8c90204-1fb7-4987-a3ff-6d4c6298a516',
            publisher: 'educational-leadership-degrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // accountingschoolguide.com
  '60f27720-30cb-48e8-8651-55d37dca737e': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '60f27720-30cb-48e8-8651-55d37dca737e',
            publisher: 'accountingschoolguide.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '60f27720-30cb-48e8-8651-55d37dca737e',
            publisher: 'accountingschoolguide.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestaccountingdegrees.net
  'e084a7ab-a4cc-440f-9edc-a19c5e7af38d': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'e084a7ab-a4cc-440f-9edc-a19c5e7af38d',
            publisher: 'bestaccountingdegrees.net',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'e084a7ab-a4cc-440f-9edc-a19c5e7af38d',
            publisher: 'bestaccountingdegrees.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // emergency-management-degree.org
  'd7eae977-0795-4697-b9f5-32991ed5025e': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'd7eae977-0795-4697-b9f5-32991ed5025e',
            publisher: 'emergency-management-degree.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd7eae977-0795-4697-b9f5-32991ed5025e',
            publisher: 'emergency-management-degree.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // cheapnursedegrees.com
  '801c46c3-e17c-4931-86ed-787b0ddfdbe8': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '801c46c3-e17c-4931-86ed-787b0ddfdbe8',
            publisher: 'cheapnursedegrees.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '801c46c3-e17c-4931-86ed-787b0ddfdbe8',
            publisher: 'cheapnursedegrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestaccountingschools.net
  'd5d900b7-8207-487f-8118-f1fd8555c4b3': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'd5d900b7-8207-487f-8118-f1fd8555c4b3',
            publisher: 'bestaccountingschools.net',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd5d900b7-8207-487f-8118-f1fd8555c4b3',
            publisher: 'bestaccountingschools.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // topcriminaljusticedegrees.org
  '6ebe1d02-44ce-4010-80e4-a34905ae328a': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '6ebe1d02-44ce-4010-80e4-a34905ae328a',
            publisher: 'topcriminaljusticedegrees.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '6ebe1d02-44ce-4010-80e4-a34905ae328a',
            publisher: 'topcriminaljusticedegrees.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestpsychologyschoolsonline.com
  'f6d3ca49-0ef4-4ebd-9657-afa13d9f2538': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'f6d3ca49-0ef4-4ebd-9657-afa13d9f2538',
            publisher: 'bestpsychologyschoolsonline.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'f6d3ca49-0ef4-4ebd-9657-afa13d9f2538',
            publisher: 'bestpsychologyschoolsonline.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestpsychologydegrees.org
  '8c2e4479-b1d5-4abb-956f-e3176a926b96': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '8c2e4479-b1d5-4abb-956f-e3176a926b96',
            publisher: 'bestpsychologydegrees.org',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '8c2e4479-b1d5-4abb-956f-e3176a926b96',
            publisher: 'bestpsychologydegrees.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // becomegunsmith.org
  '3e3494d7-2d9b-4257-88cd-283c22a13475': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '3e3494d7-2d9b-4257-88cd-283c22a13475',
            publisher: 'becomegunsmith.org',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '3e3494d7-2d9b-4257-88cd-283c22a13475',
            publisher: 'becomegunsmith.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestmastersineducation.com
  '48243cd1-57a9-4ffc-bd9f-59062f24e0a2': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '48243cd1-57a9-4ffc-bd9f-59062f24e0a2',
            publisher: 'bestmastersineducation.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '48243cd1-57a9-4ffc-bd9f-59062f24e0a2',
            publisher: 'bestmastersineducation.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestfinanceschools.net
  '013e968c-ed06-4dd6-a255-159dfc0bab66': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '013e968c-ed06-4dd6-a255-159dfc0bab66',
            publisher: 'bestfinanceschools.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '013e968c-ed06-4dd6-a255-159dfc0bab66',
            publisher: 'bestfinanceschools.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // nursing-school-rankings.com
  'abcd85ae-98fe-46be-94fb-9c961fb5c8c6': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'abcd85ae-98fe-46be-94fb-9c961fb5c8c6',
            publisher: 'nursing-school-rankings.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'abcd85ae-98fe-46be-94fb-9c961fb5c8c6',
            publisher: 'nursing-school-rankings.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // topmastersineducation.com
  'bbcc72e5-0b18-4deb-87ec-d013940573de': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'bbcc72e5-0b18-4deb-87ec-d013940573de',
            publisher: 'topmastersineducation.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'bbcc72e5-0b18-4deb-87ec-d013940573de',
            publisher: 'topmastersineducation.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // special-education-degree.net
  '4417c580-0f40-4fba-abd8-7d033855a2a0': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '4417c580-0f40-4fba-abd8-7d033855a2a0',
            publisher: 'special-education-degree.net',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '4417c580-0f40-4fba-abd8-7d033855a2a0',
            publisher: 'special-education-degree.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // topmastersinpublichealth.com
  '4d9ce16e-7e54-4d34-ac2b-dc7d0631897e': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '4d9ce16e-7e54-4d34-ac2b-dc7d0631897e',
            publisher: 'topmastersinpublichealth.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '4d9ce16e-7e54-4d34-ac2b-dc7d0631897e',
            publisher: 'topmastersinpublichealth.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // businessmba.org
  '2db93259-7293-4a5c-b899-ffbe61f1ea7a': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '2db93259-7293-4a5c-b899-ffbe61f1ea7a',
            publisher: 'businessmba.org',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '2db93259-7293-4a5c-b899-ffbe61f1ea7a',
            publisher: 'businessmba.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // top-criminal-justice-schools.net
  'af12f069-a4dc-4670-8327-be191f246165': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'af12f069-a4dc-4670-8327-be191f246165',
            publisher: 'top-criminal-justice-schools.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'af12f069-a4dc-4670-8327-be191f246165',
            publisher: 'top-criminal-justice-schools.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // mba-in-marketing.com
  '79eec6aa-738c-4eda-9a8e-6eba2ebf9d8b': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '79eec6aa-738c-4eda-9a8e-6eba2ebf9d8b',
            publisher: 'mba-in-marketing.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '79eec6aa-738c-4eda-9a8e-6eba2ebf9d8b',
            publisher: 'mba-in-marketing.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestparalegalschoolsonline.com
  '4781176b-9e94-4d29-9cf8-497700882643': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '4781176b-9e94-4d29-9cf8-497700882643',
            publisher: 'bestparalegalschoolsonline.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '4781176b-9e94-4d29-9cf8-497700882643',
            publisher: 'bestparalegalschoolsonline.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestcommunicationsdegrees.com
  '7b80cb49-85f2-43bd-a4be-af2387e99a21': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '7b80cb49-85f2-43bd-a4be-af2387e99a21',
            publisher: 'bestcommunicationsdegrees.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '7b80cb49-85f2-43bd-a4be-af2387e99a21',
            publisher: 'bestcommunicationsdegrees.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // mastersinit.org
  'd5c28ae6-3536-4695-9efc-61af18e05754': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'd5c28ae6-3536-4695-9efc-61af18e05754',
            publisher: 'mastersinit.org',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd5c28ae6-3536-4695-9efc-61af18e05754',
            publisher: 'mastersinit.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // becomecareer.com
  '9851a4b1-6e81-43a7-b8c5-33c25d9838b2': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '9851a4b1-6e81-43a7-b8c5-33c25d9838b2',
            publisher: 'becomecareer.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9851a4b1-6e81-43a7-b8c5-33c25d9838b2',
            publisher: 'becomecareer.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // master-of-health-administration.com
  '71cd52cb-4893-4727-9960-c63e1d023e4f': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '71cd52cb-4893-4727-9960-c63e1d023e4f',
            publisher: 'master-of-health-administration.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '71cd52cb-4893-4727-9960-c63e1d023e4f',
            publisher: 'master-of-health-administration.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // best-bsn-programs.com
  '252ffe6e-889e-4afc-b1a2-036fc2bac359': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '252ffe6e-889e-4afc-b1a2-036fc2bac359',
            publisher: 'best-bsn-programs.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '252ffe6e-889e-4afc-b1a2-036fc2bac359',
            publisher: 'best-bsn-programs.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // bestnursingmasters.com
  '6e906962-c33c-493f-a480-98ee248cebf1': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '6e906962-c33c-493f-a480-98ee248cebf1',
            publisher: 'bestnursingmasters.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '6e906962-c33c-493f-a480-98ee248cebf1',
            publisher: 'bestnursingmasters.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // health-science-degree.com
  'a424b81a-ac1a-4343-866d-0035cc77fdb9': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'a424b81a-ac1a-4343-866d-0035cc77fdb9',
            publisher: 'health-science-degree.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'a424b81a-ac1a-4343-866d-0035cc77fdb9',
            publisher: 'health-science-degree.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // masters-in-human-resources.org
  '9f98369e-324e-457a-8961-90660f999cc7': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '9f98369e-324e-457a-8961-90660f999cc7',
            publisher: 'masters-in-human-resources.org',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9f98369e-324e-457a-8961-90660f999cc7',
            publisher: 'masters-in-human-resources.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // top-nursing-programs.com
  'c0788d5e-8170-4df0-b963-6a0b4f684ee8': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'c0788d5e-8170-4df0-b963-6a0b4f684ee8',
            publisher: 'top-nursing-programs.com',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'c0788d5e-8170-4df0-b963-6a0b4f684ee8',
            publisher: 'top-nursing-programs.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // topdegreesonline.org
  'dcd1351c-7315-4a01-b03e-bdf1a9e817b7': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'dcd1351c-7315-4a01-b03e-bdf1a9e817b7',
            publisher: 'topdegreesonline.org',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'dcd1351c-7315-4a01-b03e-bdf1a9e817b7',
            publisher: 'topdegreesonline.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // topmarketingschools.net
  '89908ba3-1d80-4f08-9f24-ac5186ab9c62': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '89908ba3-1d80-4f08-9f24-ac5186ab9c62',
            publisher: 'topmarketingschools.net',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '89908ba3-1d80-4f08-9f24-ac5186ab9c62',
            publisher: 'topmarketingschools.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // top-business-degrees.net
  '6d646a3d-cb05-4b85-8f32-1557912295a8': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '6d646a3d-cb05-4b85-8f32-1557912295a8',
            publisher: 'top-business-degrees.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '6d646a3d-cb05-4b85-8f32-1557912295a8',
            publisher: 'top-business-degrees.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // careersinpsychology.org
  '72b76b38-bff5-47e1-8979-5197d1272104': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '72b76b38-bff5-47e1-8979-5197d1272104',
            publisher: 'careersinpsychology.org',
            url: 'https://careersinpsychology.collegedegrees.com/app/experience/',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '72b76b38-bff5-47e1-8979-5197d1272104',
            publisher: 'careersinpsychology.org',
            url: 'https://careersinpsychology.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '72b76b38-bff5-47e1-8979-5197d1272104',
            url: 'https://careersinpsychology.collegedegrees.com/app/experience/',
            publisher: 'careersinpsychology.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '72b76b38-bff5-47e1-8979-5197d1272104',
            url: 'https://careersinpsychology.collegedegrees.com/app/experience/',
            publisher: 'careersinpsychology.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '72b76b38-bff5-47e1-8979-5197d1272104',
            url: 'https://careersinpsychology.collegedegrees.com/app/experience/',
            publisher: 'careersinpsychology.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // teacher.org
  'a7082443-c035-4543-9fa8-b707a3520b84': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'a7082443-c035-4543-9fa8-b707a3520b84',
            publisher: 'teacher.org',
            url: 'https://teacher.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'a7082443-c035-4543-9fa8-b707a3520b84',
            publisher: 'teacher.org',
            url: 'https://teacher.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // mycollegeguide.org
  '274c5da2-5e48-4be7-ad90-64226981e31e': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '274c5da2-5e48-4be7-ad90-64226981e31e',
            publisher: 'mycollegeguide.org',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            url: 'https://mycollegeguide.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '274c5da2-5e48-4be7-ad90-64226981e31e',
            publisher: 'mycollegeguide.org',
            url: 'https://mycollegeguide.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // itcareerfinder.com
  '9d47c710-0b7a-4e42-9f01-b91ecd24d65b': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '9d47c710-0b7a-4e42-9f01-b91ecd24d65b',
            publisher: 'itcareerfinder.com',
            url: 'https://itcareerfinder.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9d47c710-0b7a-4e42-9f01-b91ecd24d65b',
            publisher: 'itcareerfinder.com',
            url: 'https://itcareerfinder.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // accountingdegreetoday.com
  'e8469e5d-5f02-4ffe-b6db-7cb1262548a4': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'e8469e5d-5f02-4ffe-b6db-7cb1262548a4',
            publisher: 'accountingdegreetoday.com',
            url: 'https://accountingdegreetoday.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'e8469e5d-5f02-4ffe-b6db-7cb1262548a4',
            publisher: 'accountingdegreetoday.com',
            url: 'https://accountingdegreetoday.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // discoverbusiness.us
  'a12f69e4-2de8-4a7d-abc4-74d2132f0174': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'a12f69e4-2de8-4a7d-abc4-74d2132f0174',
            publisher: 'discoverbusiness.us',
            url: 'https://discoverbusiness.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'a12f69e4-2de8-4a7d-abc4-74d2132f0174',
            publisher: 'discoverbusiness.us',
            url: 'https://discoverbusiness.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // mastersinaccounting.info
  '693cb186-3bf0-4171-a795-1f8c31ae99c5': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '693cb186-3bf0-4171-a795-1f8c31ae99c5',
            publisher: 'mastersinaccounting.info',
            url: 'https://mastersinaccounting.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '693cb186-3bf0-4171-a795-1f8c31ae99c5',
            publisher: 'mastersinaccounting.info',
            url: 'https://mastersinaccounting.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // successfulstudent.org
  'ff2e39cb-2369-403a-a3d9-3bb0719c84fb': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ff2e39cb-2369-403a-a3d9-3bb0719c84fb',
            publisher: 'successfulstudent.org',
            url: 'https://successfulstudent.collegedegrees.com/app/experience/',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ff2e39cb-2369-403a-a3d9-3bb0719c84fb',
            publisher: 'successfulstudent.org',
            url: 'https://successfulstudent.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // acceptancerate.com
  'c8168df8-6d95-479f-8107-068bef3c9890': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'c8168df8-6d95-479f-8107-068bef3c9890',
            publisher: 'acceptancerate.com',
            url: 'https://acceptancerate.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'c8168df8-6d95-479f-8107-068bef3c9890',
            publisher: 'acceptancerate.com',
            url: 'https://acceptancerate.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // registerednursing.org
  '9dabf48e-edc8-4c73-830c-a33a9dc52b2d': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '9dabf48e-edc8-4c73-830c-a33a9dc52b2d',
            publisher: 'registerednursing.org',
            url: 'https://registerednursing.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9dabf48e-edc8-4c73-830c-a33a9dc52b2d',
            publisher: 'registerednursing.org',
            url: 'https://registerednursing.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // doctoralprograms.org
  'ed2abb48-e0ea-432b-92e7-d783aa31cc6d': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ed2abb48-e0ea-432b-92e7-d783aa31cc6d',
            publisher: 'doctoralprograms.org',
            url: 'https://doctoralprograms.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ed2abb48-e0ea-432b-92e7-d783aa31cc6d',
            publisher: 'doctoralprograms.org',
            url: 'https://doctoralprograms.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // nursingschool.org
  'd5debe29-3536-4cf8-9b88-50066670101f': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'd5debe29-3536-4cf8-9b88-50066670101f',
            publisher: 'nursingschool.org',
            url: 'https://nursingschool.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd5debe29-3536-4cf8-9b88-50066670101f',
            publisher: 'nursingschool.org',
            url: 'https://nursingschool.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // onlinenursingdegrees.org
  '9e675c71-f302-4a9b-958d-558ea125250f': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '9e675c71-f302-4a9b-958d-558ea125250f',
            publisher: 'onlinenursingdegrees.org',
            url: 'https://onlinenursingdegrees.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9e675c71-f302-4a9b-958d-558ea125250f',
            publisher: 'onlinenursingdegrees.org',
            url: 'https://onlinenursingdegrees.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // onlinedegree.com
  '2a1f7856-db4f-4df7-b5c7-6af50154c5c6': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '2a1f7856-db4f-4df7-b5c7-6af50154c5c6',
            publisher: 'onlinedegree.com',
            url: 'https://onlinedegree.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '2a1f7856-db4f-4df7-b5c7-6af50154c5c6',
            publisher: 'onlinedegree.com',
            url: 'https://onlinedegree.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '2a1f7856-db4f-4df7-b5c7-6af50154c5c6',
            url: 'https://onlinedegree.collegedegrees.com/app/experience/',
            publisher: 'onlinedegree.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '2a1f7856-db4f-4df7-b5c7-6af50154c5c6',
            url: 'https://onlinedegree.collegedegrees.com/app/experience/',
            publisher: 'onlinedegree.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '2a1f7856-db4f-4df7-b5c7-6af50154c5c6',
            url: 'https://onlinedegree.collegedegrees.com/app/experience/',
            publisher: 'onlinedegree.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // morticianschool.net
  '7f80d925-bf3f-430c-afcc-cd920d47d9ab': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '7f80d925-bf3f-430c-afcc-cd920d47d9ab',
            publisher: 'morticianschool.net',
            url: 'https://morticianschool.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '7f80d925-bf3f-430c-afcc-cd920d47d9ab',
            publisher: 'morticianschool.net',
            url: 'https://morticianschool.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // nursingschools4u.com
  'bfabe4b5-388a-46c8-9047-b69b83a76e72': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'bfabe4b5-388a-46c8-9047-b69b83a76e72',
            publisher: 'nursingschools4u.com',
            url: 'https://nursingschools4u.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'bfabe4b5-388a-46c8-9047-b69b83a76e72',
            publisher: 'nursingschools4u.com',
            url: 'https://nursingschools4u.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // cybersecurityguide.org
  '134cd83c-ff4a-473b-9fd1-2320b82b2f7c': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '134cd83c-ff4a-473b-9fd1-2320b82b2f7c',
            publisher: 'cybersecurityguide.org',
            url: 'https://cybersecurityguide.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '134cd83c-ff4a-473b-9fd1-2320b82b2f7c',
            publisher: 'cybersecurityguide.org',
            url: 'https://cybersecurityguide.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // financedegreeprograms.com
  'b26f0629-0780-4130-931c-1f2f069ac374': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'b26f0629-0780-4130-931c-1f2f069ac374',
            publisher: 'financedegreeprograms.com',
            url: 'https://financedegreeprograms.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'b26f0629-0780-4130-931c-1f2f069ac374',
            publisher: 'financedegreeprograms.com',
            url: 'https://financedegreeprograms.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // abadegreeprograms.net
  '3e8373a9-86c8-446e-b329-922743009888': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '3e8373a9-86c8-446e-b329-922743009888',
            publisher: 'abadegreeprograms.net',
            url: 'https://abadegreeprograms.collegedegrees.com/app/experience/',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '3e8373a9-86c8-446e-b329-922743009888',
            publisher: 'abadegreeprograms.net',
            url: 'https://abadegreeprograms.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // onlineassociatesdegrees.net
  'c4ea4a1f-23dc-4d4e-a32b-c63546f11018': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'c4ea4a1f-23dc-4d4e-a32b-c63546f11018',
            publisher: 'onlineassociatesdegrees.net',
            url: 'https://onlineassociatesdegrees.collegedegrees.com/app/experience/',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            theme: 'none',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'c4ea4a1f-23dc-4d4e-a32b-c63546f11018',
            publisher: 'onlineassociatesdegrees.net',
            url: 'https://onlineassociatesdegrees.collegedegrees.com/app/experience/',
            theme: 'none',
          },
        },
      ],
    },
  },
  // onlineschoolsguide.net
  '1a46ed51-c13d-4ed6-897e-c750f90f0aef': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '1a46ed51-c13d-4ed6-897e-c750f90f0aef',
            publisher: 'onlineschoolsguide.net',
            theme: 'none',
            url: 'https://onlineschoolsguide.collegedegrees.com/app/experience',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '1a46ed51-c13d-4ed6-897e-c750f90f0aef',
            publisher: 'onlineschoolsguide.net',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '1a46ed51-c13d-4ed6-897e-c750f90f0aef',
            url: 'https://onlineschoolsguide.collegedegrees.com/app/experience/',
            publisher: 'onlineschoolsguide.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '1a46ed51-c13d-4ed6-897e-c750f90f0aef',
            url: 'https://onlineschoolsguide.collegedegrees.com/app/experience/',
            publisher: 'onlineschoolsguide.net',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '1a46ed51-c13d-4ed6-897e-c750f90f0aef',
            url: 'https://onlineschoolsguide.collegedegrees.com/app/experience/',
            publisher: 'onlineschoolsguide.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // academicinfluence.com
  '223c3d27-693e-40e2-93e0-c7ee956e2f6b': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '223c3d27-693e-40e2-93e0-c7ee956e2f6b',
            publisher: 'academicinfluence.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
            url: 'https://academicinfluence.collegedegrees.com/app/experience',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '223c3d27-693e-40e2-93e0-c7ee956e2f6b',
            publisher: 'academicinfluence.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // intelligent.com
  'ceb9560b-29e1-43f4-968a-9e03c8ea33a3': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ceb9560b-29e1-43f4-968a-9e03c8ea33a3',
            publisher: 'intelligent.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ceb9560b-29e1-43f4-968a-9e03c8ea33a3',
            publisher: 'intelligent.com',
            theme: 'none',
          },
        },
      ],
      'he-custom-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ceb9560b-29e1-43f4-968a-9e03c8ea33a3',
            publisher: 'intelligent.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: 'ceb9560b-29e1-43f4-968a-9e03c8ea33a3',
            url: 'https://intelligent.collegedegrees.com/app/experience/',
            publisher: 'intelligent.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ceb9560b-29e1-43f4-968a-9e03c8ea33a3',
            url: 'https://intelligent.collegedegrees.com/app/experience/',
            publisher: 'intelligent.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: 'ceb9560b-29e1-43f4-968a-9e03c8ea33a3',
            url: 'https://intelligent.collegedegrees.com/app/experience/',
            publisher: 'intelligent.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // collegeeducated.com
  '5accac15-52af-4fa4-bcf2-73668d5fa5b2': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '5accac15-52af-4fa4-bcf2-73668d5fa5b2',
            publisher: 'collegeeducated.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '5accac15-52af-4fa4-bcf2-73668d5fa5b2',
            publisher: 'collegeeducated.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // onlinemasterscolleges.com
  'cb56f5cd-d3bf-458d-8f96-009450d39b0b': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'cb56f5cd-d3bf-458d-8f96-009450d39b0b',
            publisher: 'onlinemasterscolleges.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'cb56f5cd-d3bf-458d-8f96-009450d39b0b',
            publisher: 'onlinemasterscolleges.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // cybersecurityjobs.com
  '4ee2a482-fc50-4dba-98ee-8719f6afd735': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '4ee2a482-fc50-4dba-98ee-8719f6afd735',
            publisher: 'cybersecurityjobs.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '4ee2a482-fc50-4dba-98ee-8719f6afd735',
            publisher: 'cybersecurityjobs.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // psychologyjobs.com
  '41e029bf-7078-4240-8161-45bdf4d3b259': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '41e029bf-7078-4240-8161-45bdf4d3b259',
            publisher: 'psychologyjobs.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '41e029bf-7078-4240-8161-45bdf4d3b259',
            publisher: 'psychologyjobs.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // mbastack.org
  'c7c5cb33-56bc-43bf-a31b-42705e83ef71': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'c7c5cb33-56bc-43bf-a31b-42705e83ef71',
            publisher: 'mbastack.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'c7c5cb33-56bc-43bf-a31b-42705e83ef71',
            publisher: 'mbastack.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // nurse.org
  '57cdc9f8-ea72-4365-9eea-b86bce0bdcf6': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '57cdc9f8-ea72-4365-9eea-b86bce0bdcf6',
            publisher: 'nurse.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '57cdc9f8-ea72-4365-9eea-b86bce0bdcf6',
            publisher: 'nurse.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '57cdc9f8-ea72-4365-9eea-b86bce0bdcf6',
            url: 'https://nurse.collegedegrees.com/app/experience/',
            publisher: 'nurse.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '57cdc9f8-ea72-4365-9eea-b86bce0bdcf6',
            url: 'https://nurse.collegedegrees.com/app/experience/',
            publisher: 'nurse.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '57cdc9f8-ea72-4365-9eea-b86bce0bdcf6',
            url: 'https://nurse.collegedegrees.com/app/experience/',
            publisher: 'nurse.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // universityhq.org
  'b2917221-a29b-48c0-8193-70d1750a4142': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'b2917221-a29b-48c0-8193-70d1750a4142',
            url: 'https://universityhq.collegedegrees.com/app/experience/',
            publisher: 'universityhq.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'b2917221-a29b-48c0-8193-70d1750a4142',
            url: 'https://universityhq.collegedegrees.com/app/experience/',
            publisher: 'universityhq.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: 'b2917221-a29b-48c0-8193-70d1750a4142',
            url: 'https://universityhq.collegedegrees.com/app/experience/',
            publisher: 'universityhq.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'b2917221-a29b-48c0-8193-70d1750a4142',
            url: 'https://universityhq.collegedegrees.com/app/experience/',
            publisher: 'universityhq.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: 'b2917221-a29b-48c0-8193-70d1750a4142',
            url: 'https://universityhq.collegedegrees.com/app/experience/',
            publisher: 'universityhq.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // matchcollege.com
  '064dacc3-4ce9-47a3-9004-8b87b8d3f989': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '064dacc3-4ce9-47a3-9004-8b87b8d3f989',
            publisher: 'matchcollege.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '064dacc3-4ce9-47a3-9004-8b87b8d3f989',
            publisher: 'matchcollege.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // medicalassistantcoursesonline.net
  '142ea204-ed8c-4bda-b623-27a19f65a61b': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '142ea204-ed8c-4bda-b623-27a19f65a61b',
            publisher: 'medicalassistantcoursesonline.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '142ea204-ed8c-4bda-b623-27a19f65a61b',
            publisher: 'medicalassistantcoursesonline.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // theseminarystudent.com
  '7ba339ea-aeaa-4bb8-ae2d-94bd3efa7cac': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '7ba339ea-aeaa-4bb8-ae2d-94bd3efa7cac',
            publisher: 'theseminarystudent.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '7ba339ea-aeaa-4bb8-ae2d-94bd3efa7cac',
            publisher: 'theseminarystudent.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // topmedicalassistantschools.com
  '88de5561-104c-4515-9092-fc50d216257e': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '88de5561-104c-4515-9092-fc50d216257e',
            publisher: 'topmedicalassistantschools.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '88de5561-104c-4515-9092-fc50d216257e',
            publisher: 'topmedicalassistantschools.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // counselingpsychology.org
  'e3cc0134-5a9d-4c16-a512-6f4e3906cf33': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'e3cc0134-5a9d-4c16-a512-6f4e3906cf33',
            publisher: 'counselingpsychology.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'e3cc0134-5a9d-4c16-a512-6f4e3906cf33',
            publisher: 'counselingpsychology.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // everynurse.org
  '07ec98c8-e427-4aa6-a473-6d174bb93359': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '07ec98c8-e427-4aa6-a473-6d174bb93359',
            publisher: 'everynurse.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '07ec98c8-e427-4aa6-a473-6d174bb93359',
            publisher: 'everynurse.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // forestrydegree.net
  '470d2aee-0182-486b-a7dc-9d59995f26d1': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '470d2aee-0182-486b-a7dc-9d59995f26d1',
            publisher: 'forestrydegree.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '470d2aee-0182-486b-a7dc-9d59995f26d1',
            publisher: 'forestrydegree.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // mastersinlegalstudies.com
  '63d1a3d5-07bb-43b2-8a13-f4aae39093d5': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '63d1a3d5-07bb-43b2-8a13-f4aae39093d5',
            publisher: 'mastersinlegalstudies.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '63d1a3d5-07bb-43b2-8a13-f4aae39093d5',
            publisher: 'mastersinlegalstudies.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // mastersinschoolcounseling.com
  '3a0d2516-0b44-4c90-b9ea-b6bfb5a467f7': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '3a0d2516-0b44-4c90-b9ea-b6bfb5a467f7',
            publisher: 'mastersinschoolcounseling.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '3a0d2516-0b44-4c90-b9ea-b6bfb5a467f7',
            publisher: 'mastersinschoolcounseling.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // mastersinspecialeducation.org
  '256b302a-8091-4f24-84b5-ca741244fbf4': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '256b302a-8091-4f24-84b5-ca741244fbf4',
            publisher: 'mastersinspecialeducation.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '256b302a-8091-4f24-84b5-ca741244fbf4',
            publisher: 'mastersinspecialeducation.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // toponlinedegree.org
  '15d30137-c498-4d34-ba48-be36c947b3c2': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '15d30137-c498-4d34-ba48-be36c947b3c2',
            publisher: 'toponlinedegree.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '15d30137-c498-4d34-ba48-be36c947b3c2',
            publisher: 'toponlinedegree.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // cityapplications.com
  'f18eb0b7-9eea-4086-969b-287f8d4248d1 ': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'f18eb0b7-9eea-4086-969b-287f8d4248d1 ',
            publisher: 'cityapplications.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'f18eb0b7-9eea-4086-969b-287f8d4248d1 ',
            publisher: 'cityapplications.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // onlinemftprograms.com
  'd2f9e49c-a2fd-49d4-b20a-152288f8a209': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'd2f9e49c-a2fd-49d4-b20a-152288f8a209',
            url: 'https://onlinemftprograms.collegedegrees.com/app/experience/',
            publisher: 'onlinemftprograms.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd2f9e49c-a2fd-49d4-b20a-152288f8a209',
            url: 'https://onlinemftprograms.collegedegrees.com/app/experience/',
            publisher: 'onlinemftprograms.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // forbes.com
  '46f8a9b9-ecc0-4f0c-b2a8-ab308cde7584': {
    version: 'latest',
    type: 'affiliate',
    scripts: [
      {
        src: 'ucl.scripts.cohesion.js',
        appendTo: 'head',
        properties: {
          tagular: {
            apiKey: 'src_2NyAFJNqiBPwdHkyH8uAt6g2pj0',
            sourceKey: 'src_2NyAFJNqiBPwdHkyH8uAt6g2pj0',
            writeKey: 'wk_2NyAFHeeGpX1KTckTRmFUgua1fJ',
          },
        },
      },
    ],
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '46f8a9b9-ecc0-4f0c-b2a8-ab308cde7584',
            url: 'https://forbes.collegedegrees.com/app/experience/',
            publisher: 'forbes.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '46f8a9b9-ecc0-4f0c-b2a8-ab308cde7584',
            url: 'https://forbes.collegedegrees.com/app/experience/',
            publisher: 'forbes.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '46f8a9b9-ecc0-4f0c-b2a8-ab308cde7584',
            url: 'https://forbes.collegedegrees.com/app/experience/',
            publisher: 'forbes.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '46f8a9b9-ecc0-4f0c-b2a8-ab308cde7584',
            url: 'https://forbes.collegedegrees.com/app/experience/',
            publisher: 'forbes.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '46f8a9b9-ecc0-4f0c-b2a8-ab308cde7584',
            url: 'https://forbes.collegedegrees.com/app/experience/',
            publisher: 'forbes.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // logisticsdegree.net
  'f8254fcd-a693-4517-a3da-4985b697b464': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'f8254fcd-a693-4517-a3da-4985b697b464',
            url: 'https://logisticsdegree.collegedegrees.com/app/experience/',
            publisher: 'logisticsdegree.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'f8254fcd-a693-4517-a3da-4985b697b464',
            url: 'https://logisticsdegree.collegedegrees.com/app/experience/',
            publisher: 'logisticdegree.net',
            theme: 'none',
          },
        },
      ],
    },
  },
  // psychologymastersprograms.com
  'c66c1fef-d07f-42cb-b7e3-544f79da689a': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'c66c1fef-d07f-42cb-b7e3-544f79da689a',
            url: 'https://psychologymastersprograms.collegedegrees.com/app/experience/',
            publisher: 'psychologymastersprograms.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'c66c1fef-d07f-42cb-b7e3-544f79da689a',
            url: 'https://psychologymastersprograms.collegedegrees.com/app/experience/',
            publisher: 'psychologymastersprograms.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // earlychildhoodeducationdegree.org
  '47e3bcbf-3e12-4dd4-bb0b-caba89fa2da9': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '47e3bcbf-3e12-4dd4-bb0b-caba89fa2da9',
            url: 'https://earlychildhoodeducationdegree.collegedegrees.com/app/experience/',
            publisher: 'earlychildhoodeducationdegree.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '47e3bcbf-3e12-4dd4-bb0b-caba89fa2da9',
            url: 'https://earlychildhoodeducationdegree.collegedegrees.com/app/experience/',
            publisher: 'earlychildhoodeducationdegree.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // holisticnutritiondegree.org
  '41660079-3e2b-4356-9f2d-cc8225ec5811': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '41660079-3e2b-4356-9f2d-cc8225ec5811',
            url: 'https://holisticnutritiondegree.collegedegrees.com/app/experience/',
            publisher: 'holisticnutritiondegree.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '41660079-3e2b-4356-9f2d-cc8225ec5811',
            url: 'https://holisticnutritiondegree.collegedegrees.com/app/experience/',
            publisher: 'holisticnutritiondegree.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // yescollege.com
  '4ae0b7c9-6a9e-4190-8570-4b91d5273389': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '4ae0b7c9-6a9e-4190-8570-4b91d5273389',
            url: 'https://yescollege.collegedegrees.com/app/experience/',
            publisher: 'yescollege.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '4ae0b7c9-6a9e-4190-8570-4b91d5273389',
            url: 'https://yescollege.collegedegrees.com/app/experience/',
            publisher: 'yescollege.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // medicalcodingdegree.org
  '6b3a753d-07e1-4c70-9136-5b5b0373df82': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '6b3a753d-07e1-4c70-9136-5b5b0373df82',
            url: 'https://medicalcodingdegree.collegedegrees.com/app/experience/',
            publisher: 'medicalcodingdegree.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '6b3a753d-07e1-4c70-9136-5b5b0373df82',
            url: 'https://medicalcodingdegree.collegedegrees.com/app/experience/',
            publisher: 'medicalcodingdegree.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // x-raytechnicianschools.com
  'b2d79e87-dd7d-4698-8101-a5b29fd0c1f9': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'b2d79e87-dd7d-4698-8101-a5b29fd0c1f9',
            url: 'https://x-raytechnicianschools.collegedegrees.com/app/experience/',
            publisher: 'x-raytechnicianschools.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'b2d79e87-dd7d-4698-8101-a5b29fd0c1f9',
            url: 'https://x-raytechnicianschools.collegedegrees.com/app/experience/',
            publisher: 'x-raytechnicianschools.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // addiction-counselor.org
  '2fe1871c-d39a-416f-ba76-b8d2da396933': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '2fe1871c-d39a-416f-ba76-b8d2da396933',
            url: 'https://addiction-counselor.collegedegrees.com/app/experience/',
            publisher: 'addiction-counselor.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '2fe1871c-d39a-416f-ba76-b8d2da396933',
            url: 'https://addiction-counselor.collegedegrees.com/app/experience/',
            publisher: 'addiction-counselor.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '2fe1871c-d39a-416f-ba76-b8d2da396933',
            url: 'https://addiction-counselor.collegedegrees.com/app/experience/',
            publisher: 'addiction-counselor.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '2fe1871c-d39a-416f-ba76-b8d2da396933',
            url: 'https://addiction-counselor.collegedegrees.com/app/experience/',
            publisher: 'addiction-counselor.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '2fe1871c-d39a-416f-ba76-b8d2da396933',
            url: 'https://addiction-counselor.collegedegrees.com/app/experience/',
            publisher: 'addiction-counselor.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // collegecompass.co
  '623cff1a-561a-4d05-88e1-aa6ba3b458ce': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '623cff1a-561a-4d05-88e1-aa6ba3b458ce',
            url: 'https://collegecompass.collegedegrees.com/app/experience/',
            publisher: 'collegecompass.co',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '623cff1a-561a-4d05-88e1-aa6ba3b458ce',
            url: 'https://collegecompass.collegedegrees.com/app/experience/',
            publisher: 'collegecompass.co',
            theme: 'none',
          },
        },
      ],
    },
  },
  // degreechoices.com
  '790b7c51-59bf-4b71-b82a-0c8962e16965': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '790b7c51-59bf-4b71-b82a-0c8962e16965',
            url: 'https://degreechoices.collegedegrees.com/app/experience/',
            publisher: 'degreechoices.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '790b7c51-59bf-4b71-b82a-0c8962e16965',
            url: 'https://degreechoices.collegedegrees.com/app/experience/',
            publisher: 'degreechoices.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '790b7c51-59bf-4b71-b82a-0c8962e16965',
            url: 'https://degreechoices.collegedegrees.com/app/experience/',
            publisher: 'degreechoices.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '790b7c51-59bf-4b71-b82a-0c8962e16965',
            url: 'https://degreechoices.collegedegrees.com/app/experience/',
            publisher: 'degreechoices.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '790b7c51-59bf-4b71-b82a-0c8962e16965',
            url: 'https://degreechoices.collegedegrees.com/app/experience/',
            publisher: 'degreechoices.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // thecollegecompass.com
  '65a4fc04-7cb2-49d6-bc89-56fa8eee0b6a': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '65a4fc04-7cb2-49d6-bc89-56fa8eee0b6a',
            url: 'https://thecollegecompass.collegedegrees.com/app/experience/',
            publisher: 'thecollegecompass.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '65a4fc04-7cb2-49d6-bc89-56fa8eee0b6a',
            url: 'https://thecollegecompass.collegedegrees.com/app/experience/',
            publisher: 'thecollegecompass.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // universities.com
  '249a7550-494c-4d38-8666-b1cd3c1b4169': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '249a7550-494c-4d38-8666-b1cd3c1b4169',
            url: 'https://universities.collegedegrees.com/app/experience/',
            publisher: 'universities.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '249a7550-494c-4d38-8666-b1cd3c1b4169',
            url: 'https://universities.collegedegrees.com/app/experience/',
            publisher: 'universities.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // www.skillsuccess.com
  '68d1e0d4-0f73-4903-9347-93265d5e974e': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '68d1e0d4-0f73-4903-9347-93265d5e974e',
            url: 'https://skillsuccess.collegedegrees.com/app/experience/',
            publisher: 'skillsuccess.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '68d1e0d4-0f73-4903-9347-93265d5e974e',
            url: 'https://skillsuccess.collegedegrees.com/app/experience/',
            publisher: 'skillsuccess.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  // Library Science Degrees Online
  '25e3c445-f39d-4983-a072-e48876e2f98b': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '25e3c445-f39d-4983-a072-e48876e2f98b',
            url: 'https://librarysciencedegreesonline.collegedegrees.com/app/experience/',
            publisher: 'librarysciencedegreesonline.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '25e3c445-f39d-4983-a072-e48876e2f98b',
            url: 'https://librarysciencedegreesonline.collegedegrees.com/app/experience/',
            publisher: 'librarysciencedegreesonline.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // onlinembacoach.com
  'd245fc36-dbda-4bb7-b34d-5f30579bb2c9': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'd245fc36-dbda-4bb7-b34d-5f30579bb2c9',
            url: 'https://onlinembacoach.collegedegrees.com/app/experience/',
            publisher: 'onlinembacoach.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd245fc36-dbda-4bb7-b34d-5f30579bb2c9',
            url: 'https://onlinembacoach.collegedegrees.com/app/experience/',
            publisher: 'onlinembacoach.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: 'd245fc36-dbda-4bb7-b34d-5f30579bb2c9',
            url: 'https://onlinembacoach.collegedegrees.com/app/experience/',
            publisher: 'onlinembacoach.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd245fc36-dbda-4bb7-b34d-5f30579bb2c9',
            url: 'https://onlinembacoach.collegedegrees.com/app/experience/',
            publisher: 'onlinembacoach.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: 'd245fc36-dbda-4bb7-b34d-5f30579bb2c9',
            url: 'https://onlinembacoach.collegedegrees.com/app/experience/',
            publisher: 'onlinembacoach.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // collegeaffordabilityguide.org
  'd6c3c590-578a-464f-b8cf-e9c8e9f0d457': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'd6c3c590-578a-464f-b8cf-e9c8e9f0d457',
            url: 'https://collegeaffordabilityguide.collegedegrees.com/app/experience/',
            publisher: 'collegeaffordabilityguide.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd6c3c590-578a-464f-b8cf-e9c8e9f0d457',
            url: 'https://collegeaffordabilityguide.collegedegrees.com/app/experience/',
            publisher: 'collegeaffordabilityguide.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: 'd6c3c590-578a-464f-b8cf-e9c8e9f0d457',
            url: 'https://collegeaffordabilityguide.collegedegrees.com/app/experience/',
            publisher: 'collegeaffordabilityguide.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd6c3c590-578a-464f-b8cf-e9c8e9f0d457',
            url: 'https://collegeaffordabilityguide.collegedegrees.com/app/experience/',
            publisher: 'collegeaffordabilityguide.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: 'd6c3c590-578a-464f-b8cf-e9c8e9f0d457',
            url: 'https://collegeaffordabilityguide.collegedegrees.com/app/experience/',
            publisher: 'collegeaffordabilityguide.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // accountingedu.org
  '74017d17-1570-4f3d-ab4b-fde3d72c3a33': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '74017d17-1570-4f3d-ab4b-fde3d72c3a33',
            url: 'https://accountingedu.collegedegrees.com/app/experience/',
            publisher: 'accountingedu.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '74017d17-1570-4f3d-ab4b-fde3d72c3a33',
            url: 'https://accountingedu.collegedegrees.com/app/experience/',
            publisher: 'accountingedu.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '74017d17-1570-4f3d-ab4b-fde3d72c3a33',
            url: 'https://accountingedu.collegedegrees.com/app/experience/',
            publisher: 'accountingedu.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '74017d17-1570-4f3d-ab4b-fde3d72c3a33',
            url: 'https://accountingedu.collegedegrees.com/app/experience/',
            publisher: 'accountingedu.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '5140fc52-9e0b-4464-af50-fd4f27a9d0a1',
            url: 'https://accountingedu.collegedegrees.com/app/experience/',
            publisher: 'accountingedu.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  'f2b8a7e0-f9c6-44ea-8693-1bd75ec91ec0': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'f2b8a7e0-f9c6-44ea-8693-1bd75ec91ec0',
            url: 'https://bountyhunteredu.collegedegrees.com/app/experience/',
            publisher: 'bountyhunteredu.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'f2b8a7e0-f9c6-44ea-8693-1bd75ec91ec0',
            url: 'https://bountyhunteredu.collegedegrees.com/app/experience/',
            publisher: 'bountyhunteredu.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // psydprograms.org
  '4d69cb61-456a-4607-a01d-4b78d2a51c9c': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '4d69cb61-456a-4607-a01d-4b78d2a51c9c',
            url: 'https://psydprograms.collegedegrees.com/app/experience/',
            publisher: 'psydprograms.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '4d69cb61-456a-4607-a01d-4b78d2a51c9c',
            url: 'https://psydprograms.collegedegrees.com/app/experience/',
            publisher: 'psydprograms.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '4d69cb61-456a-4607-a01d-4b78d2a51c9c',
            url: 'https://psydprograms.collegedegrees.com/app/experience/',
            publisher: 'psydprograms.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '4d69cb61-456a-4607-a01d-4b78d2a51c9c',
            url: 'https://psydprograms.collegedegrees.com/app/experience/',
            publisher: 'psydprograms.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '4d69cb61-456a-4607-a01d-4b78d2a51c9c',
            url: 'https://psydprograms.collegedegrees.com/app/experience/',
            publisher: 'psydprograms.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  '9452ea40-54ff-4c95-a760-6b38cbb29f73': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '9452ea40-54ff-4c95-a760-6b38cbb29f73',
            url: 'https://mft-license.collegedegrees.com/app/experience/',
            publisher: 'mft-license.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9452ea40-54ff-4c95-a760-6b38cbb29f73',
            url: 'https://mft-license.collegedegrees.com/app/experience/',
            publisher: 'mft-license.com',
            theme: 'none',
          },
        },
      ],
    },
  },
  'd1557430-4362-4f40-bd58-84aa371879f4': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'd1557430-4362-4f40-bd58-84aa371879f4',
            url: 'https://correctionalofficeredu.collegedegrees.com/app/experience/',
            publisher: 'correctionalofficeredu.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'd1557430-4362-4f40-bd58-84aa371879f4',
            url: 'https://correctionalofficeredu.collegedegrees.com/app/experience/',
            publisher: 'correctionalofficeredu.org',
            theme: 'none',
          },
        },
      ],
    },
  },
  // criminaljusticeprograms.com
  '53a2fdeb-7ab6-4914-a400-c472c2e71ead': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '53a2fdeb-7ab6-4914-a400-c472c2e71ead',
            url: 'https://criminaljusticeprograms.collegedegrees.com/app/experience/',
            publisher: 'criminaljusticeprograms.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '53a2fdeb-7ab6-4914-a400-c472c2e71ead',
            url: 'https://criminaljusticeprograms.collegedegrees.com/app/experience/',
            publisher: 'criminaljusticeprograms.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '53a2fdeb-7ab6-4914-a400-c472c2e71ead',
            url: 'https://criminaljusticeprograms.collegedegrees.com/app/experience/',
            publisher: 'criminaljusticeprograms.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '53a2fdeb-7ab6-4914-a400-c472c2e71ead',
            url: 'https://criminaljusticeprograms.collegedegrees.com/app/experience/',
            publisher: 'criminaljusticeprograms.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '53a2fdeb-7ab6-4914-a400-c472c2e71ead',
            url: 'https://criminaljusticeprograms.collegedegrees.com/app/experience/',
            publisher: 'criminaljusticeprograms.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // newsweek.com
  '58a9df37-6d8b-41fc-8b0b-44874f3383db': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '58a9df37-6d8b-41fc-8b0b-44874f3383db',
            url: 'https://newsweek.collegedegrees.com/app/experience/',
            publisher: 'newsweek.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '58a9df37-6d8b-41fc-8b0b-44874f3383db',
            url: 'https://newsweek.collegedegrees.com/app/experience/',
            publisher: 'newsweek.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '58a9df37-6d8b-41fc-8b0b-44874f3383db',
            url: 'https://newsweek.collegedegrees.com/app/experience/',
            publisher: 'newsweek.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '58a9df37-6d8b-41fc-8b0b-44874f3383db',
            url: 'https://newsweek.collegedegrees.com/app/experience/',
            publisher: 'newsweek.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '58a9df37-6d8b-41fc-8b0b-44874f3383db',
            url: 'https://newsweek.collegedegrees.com/app/experience/',
            publisher: 'newsweek.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // Smartypal.com
  '5140fc52-9e0b-4464-af50-fd4f27a9d0a1': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '5140fc52-9e0b-4464-af50-fd4f27a9d0a1',
            url: 'https://smartypal.collegedegrees.com/app/experience/',
            publisher: 'smartypal.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '5140fc52-9e0b-4464-af50-fd4f27a9d0a1',
            url: 'https://smartypal.collegedegrees.com/app/experience/',
            publisher: 'smartypal.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '5140fc52-9e0b-4464-af50-fd4f27a9d0a1',
            url: 'https://smartypal.collegedegrees.com/app/experience/',
            publisher: 'smartypal.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '5140fc52-9e0b-4464-af50-fd4f27a9d0a1',
            url: 'https://smartypal.collegedegrees.com/app/experience/',
            publisher: 'smartypal.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '5140fc52-9e0b-4464-af50-fd4f27a9d0a1',
            url: 'https://smartypal.collegedegrees.com/app/experience/',
            publisher: 'smartypal.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // Socialworkdegree.net
  'ed41ab64-0913-4c93-b80f-0586f7588f42': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ed41ab64-0913-4c93-b80f-0586f7588f42',
            url: 'https://socialworkdegree.collegedegrees.com/app/experience/',
            publisher: 'socialworkdegree.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ed41ab64-0913-4c93-b80f-0586f7588f42',
            url: 'https://socialworkdegree.collegedegrees.com/app/experience/',
            publisher: 'socialworkdegree.net',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: 'ed41ab64-0913-4c93-b80f-0586f7588f42',
            url: 'https://socialworkdegree.collegedegrees.com/app/experience/',
            publisher: 'socialworkdegree.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ed41ab64-0913-4c93-b80f-0586f7588f42',
            url: 'https://socialworkdegree.collegedegrees.com/app/experience/',
            publisher: 'socialworkdegree.net',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: 'ed41ab64-0913-4c93-b80f-0586f7588f42',
            url: 'https://socialworkdegree.collegedegrees.com/app/experience/',
            publisher: 'socialworkdegree.net',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // top6.com
  'e68e8c83-d482-4897-ad7f-2f7381ac8340': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'e68e8c83-d482-4897-ad7f-2f7381ac8340',
            url: 'https://top6.collegedegrees.com/app/experience/',
            publisher: 'top6.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'e68e8c83-d482-4897-ad7f-2f7381ac8340',
            url: 'https://top6.collegedegrees.com/app/experience/',
            publisher: 'top6.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: 'e68e8c83-d482-4897-ad7f-2f7381ac8340',
            url: 'https://top6.collegedegrees.com/app/experience/',
            publisher: 'top6.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'e68e8c83-d482-4897-ad7f-2f7381ac8340',
            url: 'https://top6.collegedegrees.com/app/experience/',
            publisher: 'top6.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: 'e68e8c83-d482-4897-ad7f-2f7381ac8340',
            url: 'https://top6.collegedegrees.com/app/experience/',
            publisher: 'top6.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // comparison411.com
  'a275882f-e468-4ae6-8f65-e40ae214820e': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'a275882f-e468-4ae6-8f65-e40ae214820e',
            url: 'https://comparison411.collegedegrees.com/app/experience/',
            publisher: 'comparison411.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'a275882f-e468-4ae6-8f65-e40ae214820e',
            url: 'https://comparison411.collegedegrees.com/app/experience/',
            publisher: 'comparison411.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: 'a275882f-e468-4ae6-8f65-e40ae214820e',
            url: 'https://comparison411.collegedegrees.com/app/experience/',
            publisher: 'comparison411.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'a275882f-e468-4ae6-8f65-e40ae214820e',
            url: 'https://comparison411.collegedegrees.com/app/experience/',
            publisher: 'comparison411.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: 'a275882f-e468-4ae6-8f65-e40ae214820e',
            url: 'https://comparison411.collegedegrees.com/app/experience/',
            publisher: 'comparison411.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // poetsandquants.com
  'fcf223ff-9ed1-45b5-b5cb-2739e536b823': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'fcf223ff-9ed1-45b5-b5cb-2739e536b823',
            url: 'https://poetsandquants.collegedegrees.com/app/experience/',
            publisher: 'poetsandquants.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'fcf223ff-9ed1-45b5-b5cb-2739e536b823',
            url: 'https://poetsandquants.collegedegrees.com/app/experience/',
            publisher: 'poetsandquants.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: 'fcf223ff-9ed1-45b5-b5cb-2739e536b823',
            url: 'https://poetsandquants.collegedegrees.com/app/experience/',
            publisher: 'poetsandquants.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'fcf223ff-9ed1-45b5-b5cb-2739e536b823',
            url: 'https://poetsandquants.collegedegrees.com/app/experience/',
            publisher: 'poetsandquants.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: 'fcf223ff-9ed1-45b5-b5cb-2739e536b823',
            url: 'https://poetsandquants.collegedegrees.com/app/experience/',
            publisher: 'poetsandquants.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // poetsandquantsforexecs.com
  '9044e6ff-ab46-42c1-9738-10aa833e23f6': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '9044e6ff-ab46-42c1-9738-10aa833e23f6',
            url: 'https://poetsandquantsforexecs.collegedegrees.com/app/experience/',
            publisher: 'poetsandquantsforexecs.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9044e6ff-ab46-42c1-9738-10aa833e23f6',
            url: 'https://poetsandquantsforexecs.collegedegrees.com/app/experience/',
            publisher: 'poetsandquantsforexecs.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '9044e6ff-ab46-42c1-9738-10aa833e23f6',
            url: 'https://poetsandquantsforexecs.collegedegrees.com/app/experience/',
            publisher: 'poetsandquantsforexecs.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9044e6ff-ab46-42c1-9738-10aa833e23f6',
            url: 'https://poetsandquantsforexecs.collegedegrees.com/app/experience/',
            publisher: 'poetsandquantsforexecs.com',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '9044e6ff-ab46-42c1-9738-10aa833e23f6',
            url: 'https://poetsandquantsforexecs.collegedegrees.com/app/experience/',
            publisher: 'poetsandquantsforexecs.com',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // bold.org
  '9341be8d-f1d9-4e7a-86fb-ed6059bcb07a': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '9341be8d-f1d9-4e7a-86fb-ed6059bcb07a',
            url: 'https://bold.collegedegrees.com/app/experience/',
            publisher: 'bold.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9341be8d-f1d9-4e7a-86fb-ed6059bcb07a',
            url: 'https://bold.collegedegrees.com/app/experience/',
            publisher: 'bold.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '9341be8d-f1d9-4e7a-86fb-ed6059bcb07a',
            url: 'https://bold.collegedegrees.com/app/experience/',
            publisher: 'bold.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '9341be8d-f1d9-4e7a-86fb-ed6059bcb07a',
            url: 'https://bold.collegedegrees.com/app/experience/',
            publisher: 'bold.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '9341be8d-f1d9-4e7a-86fb-ed6059bcb07a',
            url: 'https://bold.collegedegrees.com/app/experience/',
            publisher: 'bold.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // nextgraduate.org
  'ca1ab8c2-6ca5-41d0-a3fc-475ff43d5b05': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: 'ca1ab8c2-6ca5-41d0-a3fc-475ff43d5b05',
            url: 'https://nextgraduate.collegedegrees.com/app/experience/',
            publisher: 'nextgraduate.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ca1ab8c2-6ca5-41d0-a3fc-475ff43d5b05',
            url: 'https://nextgraduate.collegedegrees.com/app/experience/',
            publisher: 'nextgraduate.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: 'ca1ab8c2-6ca5-41d0-a3fc-475ff43d5b05',
            url: 'https://nextgraduate.collegedegrees.com/app/experience/',
            publisher: 'nextgraduate.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: 'ca1ab8c2-6ca5-41d0-a3fc-475ff43d5b05',
            url: 'https://nextgraduate.collegedegrees.com/app/experience/',
            publisher: 'nextgraduate.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: 'ca1ab8c2-6ca5-41d0-a3fc-475ff43d5b05',
            url: 'https://nextgraduate.collegedegrees.com/app/experience/',
            publisher: 'nextgraduate.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
  // alliedhealthschools.org
  '68b8c34f-2288-4d55-8b62-c7990f01a921': {
    version: 'latest',
    type: 'affiliate',
    presets: {
      'he-qdf': [
        {
          path: '@',
          attributes: {
            token: '68b8c34f-2288-4d55-8b62-c7990f01a921',
            url: 'https://nextgraduate.collegedegrees.com/app/experience/',
            publisher: 'alliedhealthschools.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '68b8c34f-2288-4d55-8b62-c7990f01a921',
            url: 'https://alliedhealthschools.collegedegrees.com/app/experience/',
            publisher: 'alliedhealthschools.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-wrapper': [
        {
          path: '@',
          attributes: {
            token: '68b8c34f-2288-4d55-8b62-c7990f01a921',
            url: 'https://alliedhealthschools.collegedegrees.com/app/experience/',
            publisher: 'alliedhealthschools.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
      'he-affiliate-editorial-listings': [
        {
          path: '@',
          attributes: {
            token: '68b8c34f-2288-4d55-8b62-c7990f01a921',
            url: 'https://alliedhealthschools.collegedegrees.com/app/experience/',
            publisher: 'alliedhealthschools.org',
            theme: 'none',
          },
        },
      ],
      'he-qdf-affiliate': [
        {
          path: '@',
          attributes: {
            token: '68b8c34f-2288-4d55-8b62-c7990f01a921',
            url: 'https://alliedhealthschools.collegedegrees.com/app/experience/',
            publisher: 'alliedhealthschools.org',
            theme: 'none',
            voyagerurl: 'https://discover.bestcolleges.com',
            voyagertarget: '_self',
          },
        },
      ],
    },
  },
};
